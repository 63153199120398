import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FormInput from "../FormInput";
import { useState } from "react";
import "../../styles/BBAddCustomer.css";

import { createData, updateData } from "../../../api/API";

import ToastMessage from "../ToastMessage";
const AddProduct = (props) => {
  const [values, setValues] = useState({
    product_name: "",
    product_sku: "",
    product_description: "",
    product_image: "",
    product_code: "",
    product_unit_price: "",
    product_price: [],
  });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const inputs = [
    {
      id: 1,
      name: "product_name",
      type: "text",
      placeholder: "enter your product_name",
      errorMessage: "Please enter product_name",
      label: "Product Name",
      required: true,
    },
    {
      id: 2,
      name: "product_sku",
      type: "text",
      placeholder: "product_sku",
      errorMessage: "please enter product_sku",
      label: "Product sku",
    },

    {
      id: 3,
      name: "product_description",
      type: "text",
      placeholder: "enter product_description",
      errorMessage: "Enter the product_description",
      label: "Product description",
    },
    {
      id: 4,
      name: "product_image",
      type: "text",
      placeholder: "product_image",
      errorMessage: "please enter product_image",
      label: "Product image",
    },
    {
      id: 5,
      name: "product_code",
      type: "text",
      readOnly: true,
      placeholder: "default",
      errorMessage: "please enter product_code",
      label: "Product code",
    },

    {
      id: 6,
      name: "product_unit_price",
      type: "number",
      placeholder: "number",
      errorMessage: "enter valid product_unit_price ",
      label: "Product unit price",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let code = {
      code: "default",
      unit_price: values.product_unit_price,
    };
    values.product_price.push(code);
    const resp = await createData("products", values);
    if (resp.data.status === "success") {
      setShowToast({
        flag: true,
        message: "Product added successfully",
        bg: "success",
      });
    } else {
      if (resp.data.error.includes("product_name")) {
        setShowToast({
          flag: true,
          message:
            "Product name is already present, please provide unique product name",
          bg: "danger",
        });
      } else {
        setShowToast({
          flag: true,
          message: "Something went wrong",
          bg: "danger",
        });
      }
    }
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const reset = () => {
    setValues({
      product_name: "",
      product_unit_price: "",
      product_description: "",
      product_image: "",
      product_code: "",
      product_sku: "",
      product_price: [],
    });
  };
  return (
    <>
      <Container className="add-customer-container">
        <form className="bg-secondary" onSubmit={handleSubmit}>
          <Row>
            {inputs.map((input) => (
              <React.Fragment key={input.id}>
                <Col md={6} xs={12} className="input-field">
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Row>
            <Col className="text-end">
              <Button type="reset" onClick={reset}>
                Reset
              </Button>
            </Col>
            <Col className="text-start">
              <Button type="submit">{props.buttonName}</Button>
            </Col>
          </Row>
        </form>
        {showToast.flag && (
          <ToastMessage setShowToast={setShowToast} showToast={showToast} />
        )}
      </Container>
    </>
  );
};

export default AddProduct;
