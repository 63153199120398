import { useState } from "react";
import { getDataByQuery } from "../../../api/API";
import BBSearchCustomer from "./BBSearchCustomer";
import BBAddCustomer from "./BBAddCustomer";
import LoadingSpinner from "../LoadingSpinner";
import ToastMessage from "../ToastMessage";
const UpdateCustomerInfo = () => {
  const [customer_id, setCustomer_ID] = useState("");
  const [data, setData] = useState({ data: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const customerId = async (e) => {
    setCustomer_ID(e.target.value);
  };
  const searchCustomer = async () => {
    setIsLoading(true);
    setData({ data: "" });
    const res = await getDataByQuery("bbcustomer/id", {
      customer_id: customer_id,
      search: "customer_only",
    });
    if (res.status === "success") {
      res.data.length > 0 && setData({ data: res.data[0] });
      res.data.length === 0 &&
        setShowToast({
          flag: true,
          message: "customer not found please verify ID/Phone Number",
          bg: "warning",
        });
    } else {
      setShowToast({
        flag: true,
        message: "something went wrong",
        bg: "danger",
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      <BBSearchCustomer
        name={"search customer"}
        placeholder={"enter customer id"}
        customerId={customerId}
        searchCustomer={searchCustomer}
        type="text"
      ></BBSearchCustomer>
      {data.data && <BBAddCustomer {...data} buttonName="update" />}
      {isLoading && <LoadingSpinner />}
      {showToast.flag && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}
    </>
  );
};
export default UpdateCustomerInfo;
