import React, { useEffect } from "react";
import { useState } from "react";
import ToastMessage from "../ToastMessage";
import easyinvoice from "easyinvoice";
import { Button } from "react-bootstrap";

let addspace = (count) => {
  console.log(count);
  return "&nbsp;".repeat(count);
};

let addproperspace = (word) => {
  console.log(word + " space " + (40 - word.length));
  return word + addspace(40 - word.length) + "HSN 2106";
};

const Invoice = (props) => {
  const [products, setProducts] = useState("");
  useEffect(() => {
    console.log(props);
    const productsData = props.data.products.map((e) => {
      e["description"] = addproperspace(`${e.description}`);
      delete e.discount;
      return e;
    });
    console.log(productsData);
    setProducts(productsData);
  }, [props.data.products]);

  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });

  const [disableBtn, setDisableBtn] = useState(false);

  function getSampleData() {
    return {
      // "customize": {
      //     "template": "SGVsbG8gd29ybGQh" // Must be base64 encoded html. This example contains 'Hello World!' in base64
      // },
      images: {
        logo: "https://bitbytefoods.com/images/bbflogo.jpg",
        // background: "https://public.easyinvoice.cloud/img/watermark-draft.jpg",
      },
      sender: {
        company: "Bit Byte Foods",
        address: "KIADB,Industrial area",
        city: "Navnagar, Bagalkote 587103,Karnataka",
        country: "GSTIN: 29AAZFB6660L1ZH",
        custom1: "Bank Name : Bank of Baroda",
        custom2: "A/c No. : 37550200001240",
        custom3: "Branch & IFS Code : Bagalkot & BARB0BAGALK",

        // "custom1": "custom value 1",
        // "custom2": "custom value 2",
        // "custom3": "custom value 3"
      },
      client: props.data.client,
      information: props.data.information,
      products: products,
      "bottom-notice": "Thank you",
      settings: {
        currency: "INR", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
        // "locale": "nl-NL", // Defaults to en-US, used for number formatting (see docs)
        // "margin-top": 25, // Default to 25
        // "margin-right": 25, // Default to 25
        // "margin-left": 25, // Default to 25
        // "margin-bottom": 25, // Default to 25
        // "format": "Letter", // Defaults to A4,
        // "height": "1000px", // allowed units: mm, cm, in, px
        // "width": "500px", // allowed units: mm, cm, in, px
        // "orientation": "landscape", // portrait or landscape, defaults to portrait
      },
      // Used for translating the headers to your preferred language
      // Defaults to English. Below example is translated to Dutch
      translate: {
        products: "Products" + addspace(40) + "HSN",
        vat: "GST(CSGT-2.5% + SGST-2.5%)",
        //     "invoice": "FACTUUR",
        //     "number": "Nummer",
        //     "date": "Datum",
        //     "due-date": "Verloopdatum",
        //     "subtotal": "Subtotaal",
        //     "products": "Producten",
        //     "quantity": "Aantal",
        //     "price": "Prijs",
        //     "product-total": "Totaal",
        //     "total": "Totaal"
        //		 "vat": "btw"
      },
    };
  }
  async function downloadInvoice() {
    // See documentation for all data properties
    setDisableBtn(true);
    setShowToast({
      message: "pdf is downloading please wait ",
      bg: "warning",
      flag: true,
    });
    const data = getSampleData();
    const result = await easyinvoice.createInvoice(data);

    setShowToast({
      message: "pdf is downloading please wait ",
      bg: "warning",
      flag: true,
    });
    easyinvoice.download(`${props.data.pdfFileName}`, result.pdf);
    setShowToast({
      message: "pdf downloaded successfully",
      bg: "success",
      flag: true,
    });
    setDisableBtn(false);
    //	you can download like this as well:
    //	easyinvoice.download();
    //	easyinvoice.download('myInvoice.pdf');
  }
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        {/* <button onClick={() => createInvoice()}>Create Invoice</button> */}
        <Button onClick={downloadInvoice} disabled={disableBtn}>
          Download Invoice
        </Button>
        {/* <button onClick={() => renderInvoice()}>Render Invoice</button> */}
        {/* <br />
        <br /> */}
        {/* <p>
          Invoice Base64 (click create invoice):
          <small>{state.invoiceBase64}</small>
        </p> */}
        <div id="pdf"></div>
      </div>
      {showToast.flag && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}
    </div>
  );
};

export default Invoice;
