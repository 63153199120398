import React, { useState } from "react";
import { getData } from "../../../api/API";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const GetAllCustomer = () => {
  const [data, setData] = useState({ data: [], count: 0, paginationData: [] });

  useEffect(() => {
    (async () => {
      try {
        const resp = await getData("bbcustomer");
        console.log(resp.data[0].doc);
        if (resp.data[0].doc) {
          setData({
            data: resp.data[0].doc,
            count: Math.ceil(resp.data[0].count / 10),
            paginationData: resp.data[0].doc.slice(0, 10),
          });
        } else {
          console.log("data not avalable");
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const setPaginationData = (index, totalCount) => {
    // const nodeList = document.querySelectorAll(".pages");
    // console.log(nodeList);
    // for (let i = 0; i < nodeList.length; i++) {
    //   console.log(nodeList[i]);
    //   nodeList[i].style.color = "black";
    //   nodeList[i].style.backgroundColor = "transperent";
    // }

    for (let i = 1; i <= totalCount; i++) {
      const eleid = document.getElementById(`pages${i - 1}`);
      eleid.style.color = "white";
      eleid.style.backgroundColor = "#00496d";
    }
    const eleid = document.getElementById(`pages${index - 1}`);
    console.log(eleid);
    eleid.style.color = "white";
    eleid.style.backgroundColor = "gray";
    console.log(parseInt(index + "0"));
    setData((prev) => {
      console.log(prev);
      return {
        ...prev,
        paginationData: prev.data.slice(
          parseInt(index - 1 + "0"),
          parseInt(index + "0")
        ),
      };
    });
  };
  const pages = () => {
    let arr = [];
    for (let index = 0; index < data.count; index++) {
      arr.push(
        <React.Fragment key={index}>
          <span className="px-1 bg-secondary text-light">
            <NavLink
              className={`pages pages${index} `}
              style={{
                width: "100%",
                height: "100%",
                border: 5,
                borderColor: "black",
                backgroundColor: "#c5d4b4",
              }}
              onClick={() => {
                setPaginationData(index + 1, data.count);
              }}
            >
              <div id={`pages${index}`} className="border px-3 py-1">
                {index + 1}
              </div>
            </NavLink>
          </span>
        </React.Fragment>
      );
    }
    return arr;
  };
  return (
    <>
      {data.data.length > 0 && (
        <>
          <h1>Customer Details</h1>

          <Table responsive variant="dark" striped="column" hover bordered>
            <thead>
              <tr>
                <th>#Customer ID</th>
                <th>Name</th>
                <th>Phonenumber</th>
                <th>Joined On</th>
                <th>Exp Date</th>
                <th>Current set</th>
                <th>Total Purchased</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {data.paginationData.map((e) => {
                return (
                  <React.Fragment key={e._id}>
                    <tr>
                      <td> {e.customer_id}</td>
                      <td>{e.firstName + " " + e.lastName}</td>
                      <td> {e.customer_phonenumber}</td>
                      <td>
                        {new Date(
                          new Date(e.createdAt).getTime() - 330 * 60 * 1000
                        ).toDateString()}
                      </td>
                      <td>
                        {new Date(
                          new Date(e.expire_date).getTime() - 330 * 60 * 1000
                        ).toDateString()}
                      </td>
                      <td>{e.currentSet.total}</td>
                      <td>{e.products.idli + e.products.dosa}</td>
                      <td>{`${e.address.street} ${e.address.city},${e.address.state} ${e.address.pincode}`}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      {data.data.length > 0 && (
        <div className="d-flex justify-content-center">{pages()}</div>
      )}
    </>
  );
};

export default GetAllCustomer;
