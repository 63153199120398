import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FormInput from "../FormInput";
import { useState } from "react";
import "../../styles/BBAddCustomer.css";

import { createData, updateData } from "../../../api/API";

import ToastMessage from "../ToastMessage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const AddRetailer = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    retailer_phonenumber: "",
    retailer_email: "",
    street: "",
    city: "",
    state: "Karanataka",
    pincode: "",
    gstin: "",
  });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "enter your company name",
      errorMessage: "Please enter company name",
      label: "Company Name",
      required: true,
    },
    {
      id: 3,
      name: "retailer_email",
      type: "email",
      placeholder: "email",
      errorMessage: "enter valid email adress",
      label: "Email",
    },
    {
      id: 4,
      name: "retailer_phonenumber",
      type: "text",
      placeholder: "enter contact number",
      errorMessage: "Enter the contact number",
      label: "Phone Number",
      pattern: "^[0-9]{10}$",
      maxLength: "10",
      required: true,
    },
    {
      id: 5,
      name: "street",
      type: "text",
      placeholder: "street",
      errorMessage: "please enter street name",
      label: "Street",
    },
    {
      id: 6,
      name: "city",
      type: "text",
      placeholder: "enter  city",
      errorMessage: "please enter city name",
      label: "City",
    },
    {
      id: 7,
      name: "state",
      type: "text",
      placeholder: "enter  state name",
      errorMessage: "Please enter state name",
      label: "State",
    },
    {
      id: 8,
      name: "pincode",
      type: "number",
      placeholder: "enter pin code",
      errorMessage: "Please enter pin code",
      label: "Pin Code",
    },

    {
      id: 9,
      name: "gstin",
      type: "text",
      placeholder: "enter GSTIN",
      errorMessage: "Please enter a valid GSTIN",
      label: "GSTIN",
      pattern: "^[0-9A-Za-z]{15}$",
    },
  ];
  useEffect(() => {
    /**name: "",
    retailer_phonenumber: "",
    retailer_email: "",
    street: "",
    city: "",
    state: "Karanataka",
    pincode: "",
    retailer_id: "", */
    if (props._id) {
      let newValues = {};
      for (let i in props) {
        if (
          i !== "__v" &&
          i !== "address" &&
          i !== "_id" &&
          i !== "buttonName"
        ) {
          newValues[i] = props[i];
        } else if (
          i === "address" &&
          i !== "_id" &&
          i !== "buttonName" &&
          i !== "__v"
        ) {
          const address = props[i];
          for (let j in address) {
            newValues[j] = address[j];
          }
        }
      }
      setValues(newValues);
    }
  }, []);
  const handleSubmit = async (e) => {
    console.log("add");
    e.preventDefault();
    const data = new FormData(e.target);
    const retailer = Object.fromEntries(data);
    console.log(retailer);
    retailer["address"] = {
      street: retailer.street,
      city: retailer.city,
      state: retailer.state,
      pincode: parseInt(retailer.pincode),
    };
    delete retailer.street;
    delete retailer.city;
    delete retailer.state;
    delete retailer.pincode;
    retailer["retailer_phonenumber"] = parseInt(retailer.retailer_phonenumber);
    retailer["createdAt"] = new Date(
      new Date().getTime() + 330 * 60 * 1000
    ).toISOString();
    const res = await createData("retailers", retailer);
    console.log(res.data);
    if (res.data.status === "success") {
      console.log(res.data);
      setShowToast({
        flag: true,
        message: "Retailer Added  Successfully",
        bg: "success",
      });
      setTimeout(() => {
        navigate("/invoice");
      }, 500);
    } else if (res.data.message) {
      if (res.data.message.includes("name")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer name",
          bg: "danger",
        });
      } else if (res.data.message.includes(" retailer_phonenumber")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer phone number",
          bg: "danger",
        });
      } else if (res.data.message.includes(" retailer_email")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer Email ID",
          bg: "danger",
        });
      } else if (res.data.message.includes("gstin")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer GST Number",
          bg: "danger",
        });
      } else {
        setShowToast({
          flag: true,
          message: "something went wrong",
          bg: "danger",
        });
      }
    } else {
      setShowToast({
        flag: true,
        message: "something went wrong",
        bg: "danger",
      });
    }
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const updateRetailer = async (e) => {
    e.preventDefault();
    const resp = await updateData("retailers", props._id, values);
    if (resp.data.status === "success") {
      setShowToast({
        flag: true,
        message: "retailer updated successfully",
        bg: "success",
      });
    } else if (resp.data.error) {
      if (resp.data.error.includes("name")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer name",
          bg: "danger",
        });
      } else if (resp.data.error.includes(" retailer_phonenumber")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer phone number",
          bg: "danger",
        });
      } else if (resp.data.error.includes(" retailer_email")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer Email ID",
          bg: "danger",
        });
      } else if (resp.data.error.includes("gstin")) {
        setShowToast({
          flag: true,
          message: "please provide unique retailer GST Number",
          bg: "danger",
        });
      } else {
        setShowToast({
          flag: true,
          message: "something went wrong",
          bg: "danger",
        });
      }
    }
  };
  const reset = () => {
    setValues({
      firstName: "",
      lastName: "",
      retailer_phonenumber: "",
      retailer_email: "",
      street: "",
      city: "",
      state: "",
      pincode: "",
      retailer_id: "",
    });
  };
  return (
    <>
      <Container className="add-customer-container">
        <form
          className="bg-secondary"
          onSubmit={
            props.buttonName === "Add Retailer" ? handleSubmit : updateRetailer
          }
        >
          <Row>
            {inputs.map((input) => (
              <React.Fragment key={input.id}>
                <Col md={6} xs={12} className="input-field">
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Row>
            <Col className="text-end">
              <Button type="reset" onClick={reset}>
                reset
              </Button>
            </Col>
            <Col className="text-start">
              <Button type="submit">{props.buttonName}</Button>
            </Col>
          </Row>
        </form>
        {showToast.flag && (
          <ToastMessage setShowToast={setShowToast} showToast={showToast} />
        )}
      </Container>
    </>
  );
};

export default AddRetailer;
