import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarCharts = (props) => {
  return (
    <>
      <h4 className="text-center text-primary">{props.heading}</h4>
      <BarChart
        width={parseInt(props.width)}
        height={parseInt(props.height)}
        data={props.data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={`${props.dataKey}`}
          label={{
            value: `${props.value.xaxis}`,
            offset: -2,
            position: `${props.valuePosition.xaxis}`,
          }}
        />
        <YAxis
          label={{
            value: `${props.value.yaxis}`,
            angle: -90,
            position: `${props.valuePosition.yaxis}`,
          }}
        />
        <Tooltip />
        <Legend
          verticalAlign={props.legend.verticalAlign}
          height={props.legend.height}
        />
        <Bar
          dataKey={`${props.barDataKey}`}
          fill={`${props.barFillColor}`}
          label={{
            fill: `${props.barLabelFillColor}`,
            angle: -80,
            position: `${props.valuePosition.bar}`,
          }}
          barSize={parseInt(`${props.barSize}`)}
          animationBegin={parseInt(`${props.animationBegin}`)}
          animationDuration={parseInt(`${props.animationDuration}`)}
        />
      </BarChart>
    </>
  );
};
export default BarCharts;
