import React, { useState } from "react";
import { getData } from "../../../api/API";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const GetAllRetailer = () => {
  const [data, setData] = useState({ data: [], count: 0, paginationData: [] });

  useEffect(() => {
    (async () => {
      try {
        const resp = await getData("retailers");
        console.log(resp);
        if (resp.data[0].doc) {
          setData({
            data: resp.data[0].doc,
            count: Math.ceil(resp.data[0].count / 10),
            paginationData: resp.data[0].doc.slice(0, 10),
          });
        } else {
          console.log("data not avalable");
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const setPaginationData = (index) => {
    const nodeList = document.querySelectorAll(".pages");
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].style.color = "black";
    }
    const ele = document.querySelector(`.pages${index - 1}`);
    console.log(ele);
    ele.style.color = "blue";
    console.log(parseInt(index + "0"));
    setData((prev) => {
      console.log(prev);
      return {
        ...prev,
        paginationData: prev.data.slice(
          parseInt(index - 1 + "0"),
          parseInt(index + "0")
        ),
      };
    });
  };
  const pages = () => {
    let arr = [];
    for (let index = 0; index < data.count; index++) {
      arr.push(
        <React.Fragment key={index}>
          <span className="px-1">
            <NavLink
              className={`pages pages${index} `}
              style={{
                width: "100%",
                height: "100%",
                border: 5,
                borderColor: "black",
              }}
              onClick={() => {
                setPaginationData(index + 1);
              }}
            >
              <div className="border px-3 py-1"> {index + 1}</div>
            </NavLink>
          </span>
        </React.Fragment>
      );
    }
    return arr;
  };
  return (
    <>
      {data.data.length > 0 && (
        <>
          <h1>Retailer Details</h1>

          <Table responsive variant="dark" striped="column" hover bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phonenumber</th>
                <th>GST No.</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {data.paginationData.map((e) => {
                return (
                  <React.Fragment key={e._id}>
                    <tr>
                      <td>{e.name}</td>
                      <td> {e.retailer_phonenumber}</td>
                      <td>{e.gstin}</td>
                      <td>{`${e.address.street}${e.address.city} ${e.address.state} ${e.address.pincode}`}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      {data.data.length > 0 && (
        <div className="d-flex justify-content-center">{pages()}</div>
      )}
    </>
  );
};

export default GetAllRetailer;
