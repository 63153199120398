import React, { useEffect } from "react";
import { getData } from "../../../api/API";
import { useState } from "react";

import { Table } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";

const BBRecentCustomers = () => {
  const [data, setData] = useState({ data: "" });
  useEffect(() => {
    (async () => {
      const res = await getData("bbcustomer/recentcustomers");
      if (res.status === "success") {
        if (res.data.length > 0) {
          setData({ data: res.data });
        }
      }
    })();
  }, []);
  return (
    <>
      <h4 className="text-secondary ">Recently Joined Customers</h4>
      {data.data.length === 0 && <LoadingSpinner />}
      {data.data.length > 0 && (
        <>
          <Table responsive variant="dark" striped="column" hover bordered>
            <thead>
              <tr>
                <th>#Customer ID</th>
                <th>Name</th>
                <th>Phonenumber</th>
                <th>Joined On</th>
                <th>Exp Date</th>
                <th>Current set</th>
                <th>Total Purchased</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((e) => {
                return (
                  <React.Fragment key={e._id}>
                    <tr>
                      <td> {e.customer_id}</td>
                      <td>{e.firstName + " " + e.lastName}</td>
                      <td> {e.customer_phonenumber}</td>
                      <td> {new Date(e.createdAt).toDateString()}</td>
                      <td>{new Date(e.expire_date).toDateString()}</td>
                      <td>{e.currentSet.total}</td>
                      <td>{e.products.idli + e.products.dosa}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default BBRecentCustomers;
