import React, { useEffect, useState } from "react";
import { createData } from "../../../api/API";
import { Button, Container, Row, Table } from "react-bootstrap";
import { CurrencyRupee } from "react-bootstrap-icons";
import ToastMessage from "../ToastMessage";
import Invoice from "./Invoice";
import Discount from "./Discount";
import { getDataByQuery } from "../../../api/API";
import "../../styles/InvoiceTable.css";

const InvoiceTable = (props) => {
  const [products, setProducts] = useState([]);
  const [productQty, setProductQty] = useState({});
  const [unitPrices, setUnitPrices] = useState({});
  const [grandTotal, setGrandTotal] = useState({ total: 0, gst: 0, grand: 0 });
  const [productId, setProductId] = useState("");
  const [selectedCode, setSelectedCode] = useState({});
  const [invoiceData, setInvoiceData] = useState({ data: "" });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const [date, setDate] = useState({ dateStringValue: "", ISOvalue: "" });
  const [flag, setFlag] = useState(false);
  const [productCodes, setProductCodes] = useState([]);

  useEffect(() => {
    setProducts(props.products);
    setUnitPrices(props.unitPrices);
    setProductId(props.productId);

    const getData = async () => {
      try {
        const response = await getDataByQuery("products");
        const data = response.data;
        setProductCodes(data);
      } catch (error) {
        console.error("Error fetching product codes", error);
      }
    };

    getData();
  }, [props]);

  const inputHandler = (data, action) => {
    let value = 0;
    if (data.value) {
      value = parseFloat(data.value).toFixed(2);
    }
    const newQty = {};
    newQty[action.type] = value;
    setProductQty((prev) => {
      return { ...prev, ...newQty };
    });
  };

  useEffect(() => {
    let sum = 0;
    for (let i in productQty) {
      sum = sum + productQty[i] * unitPrices[i];
    }
    setGrandTotal({
      grand: parseFloat(sum + (5 / 100) * sum).toFixed(2),
      total: parseFloat(sum).toFixed(2),
      gst: parseFloat((5 / 100) * sum).toFixed(2),
    });
  }, [productQty, unitPrices]);

  const selectedUnitPrice = (products, type) => {
    for (const product of props.products) {
      if (product.product_name === type) {
        return product.product_unit_price;
      }
    }
  };

  const discount = ({ value }, { type }) => {
    console.log(value, type);
    let newUnitPrice = {};
    // let selectedUnitPrice;
    // for (const product of props.products) {
    //   if (product.product_name === type) {
    //     selectedUnitPrice = product.product_unit_price;
    //   }
    // }
    newUnitPrice[type] = parseFloat(
      selectedUnitPrice(props.products, type) - value
    );
    setUnitPrices((prev) => {
      return { ...prev, ...newUnitPrice };
    });
  };

  const onChange = (e, data, index) => {
    const selectedCodeValue = e.target.value;
    const productName = e.target.name;
    // console.log(data,index,productName,selectedCodeValue );
    const filteredprice = data.product_price.filter((element) => {
      return selectedCodeValue === element.code;
    });
    console.log(filteredprice[0]);
    data.product_unit_price = filteredprice[0].unit_price;
    setUnitPrices((prev) => {
      return { ...prev, [productName]: filteredprice[0].unit_price };
    });
    setSelectedCode((prev) => {
      return {
        ...prev,
        [productName]: selectedCodeValue,
      };
    });

    const selectedProduct = productCodes.find(
      (product) => product.code === selectedCodeValue
    );

    if (selectedProduct) {
      setUnitPrices((prevUnitPrices) => {
        const newUnitPrices = { ...prevUnitPrices };
        newUnitPrices[productName] = selectedProduct.unit_price;
        return newUnitPrices;
      });
    }
  };

  const submitHandler = async () => {
    let obj = {};
    console.log(obj);
    obj["retailer_id"] = props.retailer_phonenumber;
    obj["date"] = date.ISOvalue ? date.ISOvalue : new Date().toISOString();
    obj["totalAmount"] = grandTotal.grand;
    obj["invoice_number"] = Math.round(Math.random() * 100000);
    let product = [];
    let onsubmitunitprice;
    for (let i in productQty) {
      if (productQty[i] !== 0) {
        onsubmitunitprice = selectedUnitPrice(props.products, i);
        product.push({
          product_id: productId[i],
          description: i,
          quantity: productQty[i],
          price: unitPrices[i],
          discount:
            onsubmitunitprice !== unitPrices[i]
              ? onsubmitunitprice - unitPrices[i]
              : 0,
          product_code: selectedCode[i],
        });
      }
    }
    obj["products"] = product;
    if (
      grandTotal.total !== `0.00` &&
      grandTotal.total !== !NaN &&
      grandTotal.total !== undefined
    ) {
      const resp = await createData("retailerinvoice", obj);
      if (resp.data.status === "success") {
        setFlag(true);
        const actualData = resp.data.data;
        const dateString = new Date(actualData.date).toLocaleDateString(
          "en-GB"
        );
        const pdfFileName = `${props.name}_${actualData.invoice_number}_${dateString}`;
        const client = {
          company: props.name,
          address: props.address.street ? props.address.street : "",
          zip: props.address.pincode ? `${props.address.pincode}` : "",
          city: props.address.city ? props.address.city : "",
          country: props.address.state ? props.address.state : "",
          custom1: props.retailer_phonenumber
            ? `Phone: ${props.retailer_phonenumber}`
            : "",
          custom2: props.gstin ? `GSTIN: ${props.gstin}` : "",
        };
        const information = {
          number: actualData.invoice_number,
          date: dateString,
        };
        const products = actualData.products.map((e) => {
          delete e._id;
          delete e.product_id;
          e["price"] = e.price;
          e["tax-rate"] = 5;
          return e;
        });
        setInvoiceData({
          data: { client, information, products, pdfFileName },
        });
        setShowToast({
          flag: true,
          message: "Added successfully",
          bg: "success",
        });
      } else {
        console.log(resp);
        setShowToast({
          flag: true,
          message: "Something went wrong",
          bg: "danger",
        });
      }
    } else {
      setShowToast({
        flag: true,
        message: "Please check out the entries",
        bg: "danger",
      });
    }
  };

  return (
    <>
      <Container className="px-5">
        <Row className="px-5">
          <div className="invoice-container">
            <div>Name: {props.name && props.name}</div>
            <div>
              Phone: {props.retailer_phonenumber && props.retailer_phonenumber}
            </div>
            <div>GST Number: {props.gstin && props.gstin}</div>
            <div>
              <label name="date">date</label>
              <input
                type="date"
                id="date"
                value={date.dateStringValue}
                onChange={(e) => {
                  const dt = new Date(e.target.value);
                  setDate((prev) => {
                    return {
                      ...prev,
                      dateStringValue: dt.toISOString().split("T")[0],
                      ISOvalue: dt.toISOString(),
                    };
                  });
                }}
              />
            </div>
            {products.length > 0 ? (
              <>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>sku</th>
                      <th>Product Name</th>
                      <th>Quantity in kg</th>
                      <th>Code</th>
                      <th>Unit Price per kg</th>
                      <th>Discount per kg</th>
                      <th>
                        <CurrencyRupee />
                        .Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((data, i) => (
                      <tr key={data._id}>
                        <td>{i + 1}</td>
                        <td>{data.product_sku}</td>
                        <td>{data.product_name}</td>
                        <td>
                          <input
                            className="text-danger"
                            type="number"
                            onChange={(e) => {
                              inputHandler(
                                { value: e.target.value, _id: data._id },
                                { type: data.product_name }
                              );
                            }}
                            readOnly={flag}
                          />
                        </td>
                        <td>
                          {/* Dropdown for Product Code with options from the backend */}
                          <select
                            value={selectedCode[data.product_name] || ""}
                            onChange={(e) => {
                              onChange(e, data, i);
                            }}
                            name={data.product_name} // Add this line to pass the product name to onChange
                            disabled={flag}
                            style={{ width: "90px", color: "black" }}
                          >
                            {/* <option value="" disabled>
                              Select Product Code
                            </option> */}
                            {data.product_price.map((product) => (
                              <option key={product.code} value={product.code}>
                                {product.code}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>{data.product_unit_price}</td>
                        <td>
                          <Discount
                            discount={discount}
                            product_name={`${data.product_name}`}
                            product_price={data.product_price}
                            unitPrices={unitPrices}
                          />
                        </td>
                        <td>
                          {productQty[data.product_name] &&
                            parseFloat(
                              productQty[data.product_name] *
                                parseFloat(unitPrices[data.product_name])
                            ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td></td> <td></td>
                      <td></td> <td></td>
                      <td></td> <td></td>
                      <td> Total</td>
                      <td>
                        <CurrencyRupee />
                        {grandTotal.total}
                      </td>
                    </tr>
                    <tr>
                      <td></td> <td></td>
                      <td></td> <td></td>
                      <td></td> <td></td>
                      <td>GST</td>
                      <td>
                        <CurrencyRupee />
                        {grandTotal.gst}
                      </td>
                    </tr>
                    <tr>
                      <td></td> <td></td>
                      <td></td> <td></td>
                      <td></td> <td></td> <td>Grand Total</td>
                      <td>
                        <CurrencyRupee />
                        {grandTotal.grand}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ textAlign: "right" }} className="px-3">
                  <Button onClick={submitHandler} disabled={flag}>
                    Add Invoice
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h1>Products are not available</h1>
              </>
            )}
          </div>
          {invoiceData.data && <Invoice data={invoiceData.data} />}
          {showToast.flag && (
            <ToastMessage showToast={showToast} setShowToast={setShowToast} />
          )}
        </Row>
      </Container>
    </>
  );
};

export default InvoiceTable;
