import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastMessage = (props) => {
  return (
    <>
      <ToastContainer
        position="top-end"
        className="p-3 position-fixed"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => props.setShowToast({ flag: false, message: "" })}
          show={props.showToast.flag}
          delay={2500}
          autohide
          bg={props.showToast.bg}
        >
          <Toast.Header></Toast.Header>
          <Toast.Body className="text-white">
            {props.showToast.message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
export default ToastMessage;
