import React from "react";
import { Sidenav, Nav } from "rsuite";
import GroupIcon from "@rsuite/icons/legacy/Group";
import Visible from "@rsuite/icons/Visible";
import Home from "@rsuite/icons/legacy/Home";
import BarChart from "@rsuite/icons/legacy/BarChart";
import AdminIcon from "@rsuite/icons/Admin";
import Off from "@rsuite/icons/legacy/Off";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import CouponIcon from "@rsuite/icons/Coupon";
import TextImageIcon from "@rsuite/icons/TextImage";
import ProjectIcon from "@rsuite/icons/Project";
import "rsuite/dist/rsuite.min.css";
import "../styles/Navigation.css";

import { useNavigate } from "react-router-dom";
import { TextIndentLeft, TextIndentRight } from "react-bootstrap-icons";
import Logout from "./Logout";
import { Project } from "@rsuite/icons";
const Navigation = (props) => {
  const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState("1");
  const navigate = useNavigate();
  const toggleOpen = () => {
    const element = document.getElementById("sidenav-container");
    element.style.display = "block";
    element.style.position = "fixed";
    const toggleopen = document.getElementById("toggleOpen");
    toggleopen.style.display = "none";
    // const toggleclose = document.getElementById("toggleClose");
    // toggleclose.style.display = "block";
    // toggleclose.style.position = "fixed";
  };
  const toggleClose = () => {
    const element = document.getElementById("sidenav-container");
    element.style.display = "none";
    const toggleopen = document.getElementById("toggleOpen");
    toggleopen.style.display = "block";
    setExpanded(true);
    // const toggleclose = document.getElementById("toggleClose");
    // toggleclose.style.display = "none";
  };
  const handleClick = (e, route) => {
    e.preventDefault();
    navigate(route);
  };
  return (
    <>
      <div
        id="toggleOpen"
        style={{ position: "fixed", width: 70, height: 40 }}
        className="nav-toggle"
      >
        <button onClick={toggleOpen}>
          <TextIndentLeft color="royalblue" size={35} />
        </button>
      </div>

      <div
        id="sidenav-container"
        style={{ width: 240 }}
        className="nav-for-mobile"
      >
        <Sidenav expanded={expanded} defaultOpenKeys={["3", "4"]}>
          <Sidenav.Body>
            <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <div className="logo">
                <img src={"/logo.png"} alt="" />
              </div>

              {props.loggedIn ? (
                <>
                  <Nav.Item
                    eventKey="9"
                    onClick={(e) => {
                      handleClick(e, "/");
                    }}
                    icon={<Home />}
                  >
                    Home
                  </Nav.Item>

                  <Nav.Item
                    onClick={(e) => {
                      handleClick(e, "/addexpense");
                    }}
                    eventKey="4"
                    icon={<MagicIcon />}
                  >
                    Expenses
                  </Nav.Item>
                  <Nav.Item
                    onClick={(e) => {
                      handleClick(e, "/retailer");
                    }}
                    eventKey="5"
                    icon={<ProjectIcon />}
                  >
                    Retailer
                  </Nav.Item>
                  <Nav.Item
                    className="addexp"
                    eventKey="6"
                    onClick={(e) => {
                      handleClick(e, "/bitbyterewards");
                    }}
                    icon={<CouponIcon />}
                  >
                    Bit Byte Rewards
                  </Nav.Item>
                  <Nav.Item
                    className="addexp"
                    eventKey="7"
                    onClick={(e) => {
                      handleClick(e, "/invoice");
                    }}
                    icon={<TextImageIcon />}
                  >
                    Invoice
                  </Nav.Item>
                  <Nav.Item
                    className="addexp"
                    eventKey="8"
                    onClick={(e) => {
                      handleClick(e, "/reportmonthly");
                    }}
                    icon={<BarChart />}
                  >
                    Reports
                  </Nav.Item>
                  <Nav.Item eventKey="9" icon={<Off />}>
                    <Logout onLogout={props.onLogout} />
                  </Nav.Item>
                </>
              ) : (
                <>
                  <Nav.Item
                    onClick={(e) => {
                      handleClick(e, "/signup");
                    }}
                    eventKey="1"
                    icon={<Visible />}
                  >
                    register
                  </Nav.Item>
                  <Nav.Item
                    onClick={(e) => {
                      handleClick(e, "/login");
                    }}
                    eventKey="2"
                    icon={<AdminIcon />}
                  >
                    login
                  </Nav.Item>
                </>
              )}

              <button onClick={toggleClose}>
                <TextIndentRight color="royalblue" size={35} />
              </button>
            </Nav>
          </Sidenav.Body>
          <Sidenav.Toggle
            onToggle={(expanded) => setExpanded(expanded)}
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </Sidenav>
      </div>
    </>
  );
};
export default Navigation;
