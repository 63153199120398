import React from "react";
import BBSerachCustomer from "../bbcustomer/BBSearchCustomer";
import { useState } from "react";
import { getDataByQuery } from "../../../api/API";
import AddInvoice from "./AddInvoice";
import ToastMessage from "../ToastMessage";
import LoadingSpinner from "../LoadingSpinner";
import SearchIndivisualRetailer from "./SearchIndivisualRetailer";

const SearchRetailer = (props) => {
  const [retailerID, setRetailerID] = useState("");
  const [countRetailers, setCountRetailers] = useState({ data: [] });
  const [retailerInfo, setRetailerInfo] = useState("");
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const searchRetailer = async () => {
    setIsLoading(true);
    setRetailerInfo("");
    setCountRetailers({ data: [] });
    const res = await getDataByQuery("retailers/getbyquery", {
      id: retailerID,
    });
    if (res.status === "success") {
      if (res.data && res.data.length > 0) {
        if (res.data.length > 1) {
          setCountRetailers((prev) => {
            return { ...prev, data: res.data };
          });
        } else {
          setRetailerInfo(res.data[0]);
        }
      } else {
        setShowToast({
          flag: true,
          message: "Retailer Not Found",
          bg: "danger",
        });
      }
    } else {
      console.log(res);
      setShowToast({
        flag: true,
        message: "Something went wrong",
        bg: "danger",
      });
    }
    setIsLoading(false);
  };
  const setRetailer = (e) => {
    setCountRetailers({ data: [] });
    setRetailerInfo("");
    setRetailerID(e.target.value);
  };
  return (
    <>
      <BBSerachCustomer
        searchCustomer={searchRetailer}
        customerId={setRetailer}
        name={"search retailer"}
        type="text"
        placeholder="Enter reatiler name or phonenumber"
      />
      {countRetailers.data.length > 1 && (
        <>
          <strong>please select reatiler:</strong>
          {countRetailers.data.map((e) => {
            return (
              <React.Fragment key={e._id}>
                <div className="search-customer-input-container search-customer-input">
                  <button
                    onClick={() => {
                      setCountRetailers({ data: e });
                      setRetailerInfo(e);
                    }}
                  >
                    {e.name}
                  </button>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
      {isLoading && <LoadingSpinner />}
      {retailerInfo && props.search === undefined && (
        <AddInvoice {...retailerInfo} />
      )}
      {retailerInfo && props.search === "information" && (
        <SearchIndivisualRetailer {...retailerInfo} />
      )}
      {showToast.flag && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}
    </>
  );
};

export default SearchRetailer;
