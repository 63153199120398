import React from "react";
import { getData } from "../../api/API";
const Logout = (props) => {
  const logout = async () => {
    const res = await getData("users/logout");
    console.log(res);
    if (res.flag) {
      localStorage.clear();
      props.onLogout();
    }
  };
  return (
    <span style={{ width: "100%" }} onClick={logout}>
      logout
    </span>
  );
};

export default Logout;
