import React, { useState } from "react";
import { Calendar } from "react-date-range";
import { getDataByQuery } from "../../api/API";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import ExpenseForm from "./ExpenseForm";
import { updateData } from "../../api/API";
import { useNavigate } from "react-router-dom";
import ExpensePage from "../../views/ExpensePage";
import LoadingSpinner from "./LoadingSpinner";
const UpdateExpense = () => {
  const [updateID, setUpdateID] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dateTime, setDatetime] = useState({ date: "", time: "" });
  const navigate = useNavigate();
  const n = (n) => {
    return n > 9 ? n : "0" + n;
  };
  const handleSelect = (data) => {
    console.log(localStorage.getItem("user_id"));
    const x = `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
    const dateArr = x.split("/");
    let num = dateArr
      .map((e) => {
        return n(e);
      })
      .reduce((accumulator, ele) => {
        if (ele.length !== 4) return (accumulator = accumulator + ele + "/");
        else return (accumulator = accumulator + ele);
      }, "");
    setDate(num);
    setCalendarOpen(!isCalendarOpen);
  };
  const open_close = () => {
    setCalendarOpen(!isCalendarOpen);
  };
  const getExistingData = async () => {
    setIsLoading(true);
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      const res = await getDataByQuery("expenses/user_id_date", {
        user_id: user_id,
        date: date,
      });
      console.log(res);
      if (res.expense)
        if (res.expense.length > 0) {
          const expense = res.expense[0].expense;
          console.log(expense);
          let arr = [];
          res.items.map((e) => {
            let obj = {};
            for (let i = 0; i < expense.items.length; i++) {
              if (expense.items[i].item_id === e._id) {
                console.log(expense.items[i]);
                obj["amount"] = expense.items[i].amount;
                obj["quantity"] = expense.items[i].quantity
                  ? expense.items[i].quantity
                  : "";
                break;
              } else {
                obj["amount"] = "";
                obj["quantity"] = "";
              }
            }

            obj["item_id"] = e._id;
            obj["name"] = e.item_name;
            obj["item_image"] = e.item_image;
            obj["item_category"] = e.item_category;
            arr.push(obj);
            return "";
          });
          setUpdateID(expense._id);
          setInputFields(arr);
          setDatetime({ date: expense.create_date, time: expense.create_time });
        } else {
          console.log("expense data is not available for selected date ");
          setInputFields([]);
        }
      else {
        console.log(res);
      }
    } else {
      console.log("user not logged in");
      navigate("/login");
    }
    setIsLoading(false);
  };
  const createExpense = async (expense) => {
    try {
      console.log(expense, updateID);
      const res = await updateData("expenses", updateID, expense);
      console.log(res);
      return res;
    } catch (e) {
      console.log("error while updating");
    }
  };

  return (
    <>
      <ExpensePage />
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <button className="bg-light text-black rounded" onClick={open_close}>
            select date:
            <input style={{ border: 0 }} type="text" readOnly value={date} />
          </button>
          {isCalendarOpen && (
            <Calendar date={new Date()} onChange={handleSelect} />
          )}
          <button
            className="bg-light text-black rounded"
            onClick={getExistingData}
          >
            ok
          </button>
        </div>

        {isLoading && <LoadingSpinner />}
        <ExpenseForm
          initialData={inputFields}
          createExpense={createExpense}
          saveButton={"update"}
          dateTime={dateTime}
        />
      </div>
    </>
  );
};

export default UpdateExpense;
