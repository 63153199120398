import React, { useEffect } from "react";
import { getData, createData } from "../../api/API";
import { useState } from "react";
import ExpenseForm from "./ExpenseForm";
import { useNavigate } from "react-router-dom";
import ExpensePage from "../../views/ExpensePage";
import LoadingSpinner from "./LoadingSpinner";

const Expense = (props) => {
  const [inputFields, setInputFields] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const response = await getData("items");
      console.log(response);
      if (response.status === "success") {
        let arr = [];
        response.data.map((e) => {
          let obj = {};
          obj["amount"] = "";
          obj["item_id"] = e._id;
          obj["name"] = e.item_name;
          obj["item_image"] = e.item_image;
          obj["item_category"] = e.item_category;
          arr.push(obj);
          return "";
        });
        setInputFields(arr);
      } else {
        navigate("/login");
      }
    })();
  }, [navigate]);
  // const getItems = async () => {
  // const response = await getData("items");
  // let arr = [];
  // response.data.map((e) => {
  //   let obj = {};
  //   obj["amount"] = "";
  //   obj["item_id"] = e._id;
  //   obj["name"] = e.item_name;
  //   obj["item_image"] = e.item_image;
  //   arr.push(obj);
  //   return "";
  // });
  // setInputFields(arr);
  // };

  const createExpense = async (expense) => {
    try {
      const res = await createData("expenses", expense);
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <ExpensePage />
      {inputFields.length === 0 && <LoadingSpinner />}
      {inputFields.length > 0 && (
        <ExpenseForm
          initialData={inputFields}
          createExpense={createExpense}
          saveButton={"Save"}
        />
      )}
    </div>
  );
};

export default Expense;
