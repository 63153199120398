import React from "react";
import { NavLink } from "react-router-dom";

const ReportPage = () => {
  return (
    <div style={{ margin: "5px" }} className="d-flex justify-content-center">
      <div className="p-2">
        <button className="btn btn-primary">
          <NavLink className={"text-white"} to="/reportmonthly">
            <strong>Monthly Report</strong>
          </NavLink>
        </button>
      </div>
      <div className="p-2">
        <button className="btn btn-primary">
          <NavLink className={"text-white"} to="/reportyearly">
            <strong>Yearly Report</strong>
          </NavLink>
        </button>
      </div>
      <div className="p-2">
        <button className="btn btn-primary">
          <NavLink className={"text-white"} to="/reportbyitem">
            <strong>Monthly Item Report</strong>
          </NavLink>
        </button>
      </div>
    </div>
  );
};

export default ReportPage;
