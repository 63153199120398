import React, { useEffect } from "react";
import { getData } from "../../../api/API";
import { useState } from "react";

import { Table } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";

const RecentInvoice = () => {
  const [data, setData] = useState({ data: "" });
  useEffect(() => {
    (async () => {
      const res = await getData("retailerinvoice/recentinvoices");
      console.log(res);
      if (res.status === "success") {
        if (res.data.length > 0) {
          setData({ data: res.data });
        }
      }
    })();
  }, []);
  return (
    <>
      <h4 className="text-secondary ">Recent Invoices</h4>
      {data.data.length === 0 && <LoadingSpinner />}
      {data.data.length > 0 && (
        <>
          <Table responsive variant="dark" striped="column" hover bordered>
            <thead>
              <tr>
                <th>#Invoice</th>
                <th>Retailer Name</th>
                <th>Date</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((e) => {
                return (
                  <React.Fragment key={e._id}>
                    <tr>
                      <td>{e.invoice_number}</td>
                      <td>{e.retailer[0].name}</td>
                      <td>
                        {e.date
                          ? new Date(e.date).toLocaleDateString("en-GB")
                          : ""}
                      </td>
                      <td>{e.totalAmount}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default RecentInvoice;
