import { Button, Col, Container, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

const Invoice = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container style={{ width: "80%" }}>
        <Row>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to={"/createinvoice"} className="text-black">
                Generate Invoice
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate("/getallinvoices");
              }}
            >
              Get All Invoices
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate("/downloadinvoice");
              }}
            >
              Download Invoice
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Invoice;
