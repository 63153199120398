import React from "react";
import BBSerachCustomer from "../bbcustomer/BBSearchCustomer";
import { useState } from "react";
import { getDataByQuery } from "../../../api/API";
import AddRetailer from "./AddRetailer";
import LoadingSpinner from "../LoadingSpinner";
import ToastMessage from "../ToastMessage";

function BBRetailerUpdate() {
  const [retailerID, setRetailerID] = useState("");
  const [data, setData] = useState({ data: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const retailerId = (e) => {
    setRetailerID(e.target.value);
  };
  const searchRetailer = async () => {
    setData({ data: "" });
    setIsLoading(true);
    const resp = await getDataByQuery("retailers/getbyquery", {
      id: retailerID,
    });
    if (resp.status === "success") {
      if (resp.data) {
        if (resp.data.length > 0) {
          console.log(resp.data[0]);
          setData({ data: resp.data[0] });
        } else {
          setShowToast({
            flag: true,
            message: "retailer not found",
            bg: "danger",
          });
        }
      } else {
        console.log(resp);
        setShowToast({
          flag: true,
          message: "Something went wrong.",
          bg: "danger",
        });
      }
    } else {
      setShowToast({
        flag: true,
        message: "Something went wrong.",
        bg: "danger",
      });
    }
    setIsLoading(false);
  };
  return (
    <div>
      <BBSerachCustomer
        placeholder={"enter retailer name or phone number"}
        customerId={retailerId}
        searchCustomer={searchRetailer}
        name="search retailer"
        type="text"
      />
      {data.data && (
        <>
          <AddRetailer {...data.data} buttonName="Update Retailer" />
        </>
      )}
      {isLoading && <LoadingSpinner />}

      {showToast.flag && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}
    </div>
  );
}

export default BBRetailerUpdate;
