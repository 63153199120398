import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import FormInput from "../FormInput";
import { useState } from "react";
import "../../styles/BBAddCustomer.css";
import { createData, updateData } from "../../../api/API";
import ToastMessage from "../ToastMessage";
import { useNavigate } from "react-router-dom";
const BBAddCustomer = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    customer_phonenumber: "",
    customer_email: "",
    street: "",

    city: "Bagalkot",
    state: "Karnataka",
    pincode: "587101",

    idli: 0,
    dosa: 0,
    dob: "",
    customer_id: "",
  });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "enter your first name",
      errorMessage: "Please enter first name",
      label: "First Name",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "enter your last name",
      errorMessage: "Please enter first name",
      label: "Last Name",
    },
    {
      id: 3,
      name: "customer_email",
      type: "email",
      placeholder: "email",
      errorMessage: "enter valid email adress",
      label: "Email",
    },
    {
      id: 4,
      name: "customer_phonenumber",
      type: "text",
      placeholder: "enter contact number",
      errorMessage: "Enter the contact number",
      label: "Phone Number",
      pattern: "^[0-9]{10}$",
      maxLength: "10",
      required: true,
    },
    {
      id: 5,
      name: "street",
      type: "text",
      placeholder: "street",
      errorMessage: "please enter street name",
      label: "Street",
    },
    {
      id: 6,
      name: "city",
      type: "text",
      placeholder: "enter  city",
      errorMessage: "please enter city name",
      label: "City",
      required: true,
    },
    {
      id: 7,
      name: "state",
      type: "text",
      placeholder: "enter  state name",
      errorMessage: "Please enter state name",
      label: "State",
      required: true,
    },
    {
      id: 8,
      name: "pincode",
      type: "number",
      placeholder: "enter pin code",
      errorMessage: "Please enter pin code",
      label: "Pin Code",
      required: true,
    },
    {
      id: 9,
      name: "idli",
      type: "number",
      placeholder: "enter your first name",
      errorMessage: "enter number of idli packets",
      label: "Idli",
    },
    {
      id: 10,
      name: "dosa",
      type: "number",
      placeholder: "enter your first name",
      errorMessage: "enter number of dosa packets",
      label: "Dosa",
    },
    {
      id: 11,
      name: "customer_id",
      type: "text",
      placeholder: "customer id",
      errorMessage: "customer id should be 4 digit",
      label: "Customer ID",
      pattern: "^[0-9]{4}$",
      maxLength: "4",
      required: true,
    },
    {
      id: 12,
      name: "dob",
      type: "date",
      placeholder: "enter DOB",
      errorMessage: "Please enter DOB",
      label: "DOB",
    },
  ];
  useEffect(() => {
    if (props.data) {
      const customer = props.data;
      console.log(customer);
      let c = {};
      for (let i in customer) {
        if (
          i !== "__v" &&
          i !== "updatedAt" &&
          i !== "dob" &&
          i !== "address" &&
          i !== "currentSet" &&
          i !== "freePacketsGiven" &&
          i !== "products" &&
          i !== "createdAt"
        )
          c[i] = customer[i];
        else if (i === "address") {
          let address = customer[i];
          for (let j in address) {
            c[j] = address[j];
          }
        } else if (i === "dob") {
          let dob = "";
          let date = customer[i];
          if (date) {
            date = new Date(customer[i]);
            dob =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              `${
                date.getDate().toString().length > 1
                  ? date.getDate()
                  : `0${date.getDate()}`
              }`;
          }
          c[i] = dob;
        } else if (i === "products") {
          const products = customer[i];
          for (let j in products) {
            c[j] = products[j];
          }
        }
      }
      console.log(c);
      setValues(c);
    }
  }, [props.data]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const customer = Object.fromEntries(data);
    console.log(customer);
    customer["address"] = {
      street: customer.street,
      city: customer.city,
      state: customer.state,
      pincode: parseInt(customer.pincode),
    };
    if (customer.idli === "" && customer.dosa === "") {
      if (customer.idli === "" && customer.dosa === "") {
        customer["products"] = {
          idli: 0,
          dosa: 0,
        };
        customer["currentSet"] = {
          total: 0,
          eligibleForExtraPacket: 0,
        };
      } else if (customer.idli === "") {
        customer["products"] = {
          idli: 0,
          dosa: parseInt(customer.dosa),
        };
        customer["currentSet"] = {
          total: (parseInt(0) + parseInt(customer.dosa)) % 10,
          eligibleForExtraPacket: Math.floor(
            (parseInt(0) + parseInt(customer.dosa)) / 10
          ),
        };
      } else if (customer.dosa === "") {
        customer["products"] = {
          idli: parseInt(customer.idli),
          dosa: 0,
        };
        customer["currentSet"] = {
          total: (parseInt(customer.idli) + parseInt(0)) % 10,
          eligibleForExtraPacket: Math.floor(
            (parseInt(customer.idli) + parseInt(0)) / 10
          ),
        };
      }
    } else {
      customer["products"] = {
        idli: parseInt(customer.idli),
        dosa: parseInt(customer.dosa),
      };
      customer["currentSet"] = {
        total: (parseInt(customer.idli) + parseInt(customer.dosa)) % 10,
        eligibleForExtraPacket: Math.floor(
          (parseInt(customer.idli) + parseInt(customer.dosa)) / 10
        ),
      };
    }
    customer["customer_id"] = parseInt(customer.customer_id);
    customer["customer_phonenumber"] = parseInt(customer.customer_phonenumber);
    if (customer.dob) customer["dob"] = new Date(customer.dob).toISOString();
    else customer["dob"] = "";
    customer["freePacketsGiven"] = 0;
    const date = new Date();
    customer["createdAt"] = new Date(
      date.getTime() + 330 * 1000 * 60
    ).toISOString();
    customer["updatedAt"] = new Date(
      date.getTime() + 330 * 1000 * 60
    ).toISOString();
    customer["expire_date"] = new Date(
      new Date(date.setMonth(date.getMonth() + 2)).setDate(date.getDate() - 1)
    ).toISOString();
    delete customer.idli;
    delete customer.dosa;
    delete customer.city;
    delete customer.state;
    delete customer.pincode;
    delete customer.street;

    const res = await createData("bbcustomer", customer);
    if (res.data.status === "success") {
      console.log(res.data);
      setShowToast({
        flag: true,
        message: "Customer Added  Successfully",
        bg: "success",
      });
      setTimeout(() => {
        navigate("/bitbyterewards");
      }, 500);
    } else if (res.data.message) {
      if (res.data.message.includes("customer_id")) {
        setShowToast({
          flag: true,
          message: "please provide unique customer id",
          bg: "danger",
        });
      } else if (res.data.message.includes("customer_phonenumber")) {
        setShowToast({
          flag: true,
          message: "please provide unique customer phone number",
          bg: "danger",
        });
      } else if (res.data.message.includes("customer_email")) {
        setShowToast({
          flag: true,
          message: "please provide unique customer email",
          bg: "danger",
        });
      } else {
        setShowToast({
          flag: true,
          message: "something went wrong",
          bg: "danger",
        });
      }
    } else {
      setShowToast({
        flag: true,
        message: "something went wrong",
        bg: "danger",
      });
    }
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const reset = () => {
    setValues({
      firstName: "",
      lastName: "",
      customer_phonenumber: "",
      customer_email: "",
      street: "",
      city: "",
      state: "",
      pincode: "",
      idli: 0,
      dosa: 0,
      dob: "",
      customer_id: "",
    });
  };
  const updateCustomerInfo = async (e) => {
    e.preventDefault();
    values["address"] = {
      street: values.street,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
    };
    const resp = await updateData(
      "bbcustomer/bbupdateinfo",
      values._id,
      values
    );
    if (resp.data.status) {
      setShowToast({
        flag: true,
        message: "updated successfully",
        bg: "success",
      });
    } else {
      const err = resp.data.error;
      if (err) {
        if (err.includes("customer_phonenumber")) {
          setShowToast({
            flag: true,
            message: "enter a unique phone number",
            bg: "danger",
          });
        } else if (err.includes("customer_id")) {
          setShowToast({
            flag: true,
            message: "enter a unique Customer ID",
            bg: "danger",
          });
        } else {
          setShowToast({
            flag: true,
            message: "something went wrong",
            bg: "danger",
          });
        }
      }
    }
  };
  return (
    <>
      <Container className="add-customer-container py-3">
        <form
          className="bg-secondary py-3"
          onSubmit={
            props.buttonName === "update" ? updateCustomerInfo : handleSubmit
          }
        >
          <Row>
            {inputs.map((input) => (
              <React.Fragment key={input.id}>
                <Col md={6} xs={12} className="input-field">
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Row className="start-end">
            <Col className="text-end">
              <Button type="reset" onClick={reset}>
                reset
              </Button>
            </Col>
            <Col className="text-start">
              <Button type="submit">{props.buttonName}</Button>
            </Col>
          </Row>
        </form>
        {showToast.flag && (
          <ToastMessage setShowToast={setShowToast} showToast={showToast} />
        )}
      </Container>
    </>
  );
};

export default BBAddCustomer;
