import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import FormInput from "../FormInput";
import { getDataByQuery, updateData } from "../../../api/API";
import ToastMessage from "../ToastMessage";
import BBSerachCustomer from "../bbcustomer/BBSearchCustomer";
import "../../styles/UpdateProduct.css";

const UpdateProduct = (props) => {
  const [searchitem, setSearchItem] = useState("");
  const [values, setValues] = useState({
    _id: "",
    product_name: "",
    product_unit_price: "",
    product_description: "",
    product_image: "",
    product_code: "",
    product_sku: "",
    product_price: [],
  });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const [productCodes, setProductCodes] = useState([]);
  const [newProductCode, setNewProductCode] = useState([]);

  const inputs = [
    {
      id: 1,
      name: "product_name",
      type: "text",
      placeholder: "Enter your product_name",
      errorMessage: "Please enter product_name",
      label: "Product Name",
      required: true,
    },
    {
      id: 2,
      name: "product_sku",
      type: "text",
      placeholder: "Product_sku",
      errorMessage: "Please enter product_sku",
      label: "Product sku",
    },
    {
      id: 3,
      name: "product_description",
      type: "text",
      placeholder: "Enter product_description",
      errorMessage: "Enter the product_description",
      label: "Product description",
    },
    {
      id: 4,
      name: "product_image",
      type: "text",
      placeholder: "Product_image",
      errorMessage: "Please enter product_image",
      label: "Product image",
    },
    {
      id: 5,
      name: "product_code",
      type: "text",
      placeholder: "Product_code",
      errorMessage: "Please enter product_code",
      label: "Product code",
    },
    {
      id: 6,
      name: "product_unit_price",
      type: "number",
      placeholder: "Enter unit price",
      errorMessage: "Enter valid product_unit_price ",
      label: "Product unit price",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let id = values._id;
    const resp = await updateData("products", id, {
      ...values,
      product_price: [...productCodes, ...newProductCode],
    });

    if (resp.data.status === "success") {
      setShowToast({
        flag: true,
        message: "Product updated successfully",
        bg: "success",
      });
    } else {
      setShowToast({
        flag: true,
        message: "Something went wrong",
        bg: "danger",
      });
    }
  };

  const onChange = (e) => {
    const selectedCode = e.target.value;
    const selectedProduct = productCodes.find(
      (code) => code.code === selectedCode
    );

    setValues({
      ...values,
      [e.target.name]: selectedCode,
      product_unit_price: selectedProduct ? selectedProduct.unit_price : "",
    });
  };

  const reset = () => {
    setValues({
      _id: "",
      product_name: "",
      product_unit_price: "",
      product_description: "",
      product_image: "",
      product_code: "",
      product_sku: "",
      product_price: [],
    });
    setProductCodes([]);
    setNewProductCode([]);
  };

  const handleAddProductCode = () => {
    const updatedProductCodes = [
      ...newProductCode,
      { code: "", unit_price: 0 },
    ];

    // Update the state with the new array of product codes
    setNewProductCode(updatedProductCodes);
  };

  const handleProductCodeChange = (index, key, value) => {
    const updatedProductCodes = [...newProductCode];
    updatedProductCodes[index][key] = value;
    setNewProductCode(updatedProductCodes);

    setValues((prevValues) => ({
      ...prevValues,
      product_price: updatedProductCodes,
    }));
  };

  const renderProductCodeFields = () => {
    return newProductCode.map((element, index) => {
      const elementKey = `${index}_${element.code}`;

      return (
        <React.Fragment key={index}>
          <Col md={6} xs={12} className="input-field">
            <FormInput
              name={`product_price[${index}].code`}
              type="text"
              placeholder="New product code"
              label="New product code"
              value={newProductCode[index].code}
              // onChange={onChange}
              onChange={(e) =>
                handleProductCodeChange(index, "code", e.target.value)
              }
            />
          </Col>
          <Col md={6} xs={12} className="input-field">
            <FormInput
              name={`product_price[${index}].unit_price`}
              type="number"
              label="New unit price"
              placeholder="New unit Price"
              value={newProductCode[index].unit_price}
              onChange={(e) =>
                handleProductCodeChange(index, "unit_price", e.target.value)
              }
            />
          </Col>
        </React.Fragment>
      );
    });
  };

  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSearch = async () => {
    try {
      const resp = await getDataByQuery(`products/searchproductbyname`, {
        product_name: searchitem,
      });
      if (resp.status === "success") {
        setSearchResults(resp.data);
        if (resp.data.length === 1) {
          setValues(resp.data[0]);
          setProductCodes(resp.data[0].product_price);
        } else {
          setSelectedProduct(null);
        }
      } else {
        setShowToast({
          flag: true,
          message: "Product not found",
          bg: "danger",
        });
      }
    } catch (error) {
      setShowToast({
        flag: true,
        message: "Something went wrong",
        bg: "danger",
      });
    }
  };

  const handleProductSelect = (selectedProductId) => {
    setNewProductCode([]);
    const selectedProduct = searchResults.find(
      (product) => product._id === selectedProductId
    );
    setValues(selectedProduct);
    const default_productcode = {
      code: selectedProduct["product_code"],
      unit_price: selectedProduct["product_unit_price"],
    };
    let product_prices;
    if (selectedProduct.product_price) {
      product_prices = selectedProduct.product_price;
    } else {
      product_prices = [];
    }
    setProductCodes([...product_prices]);
    setSelectedProduct(selectedProductId);
  };

  const searchItem = (e) => {
    setSearchItem(e.target.value);
  };

  return (
    <Container className="update-product-container" style={{ width: "80%" }}>
      <BBSerachCustomer
        placeholder={"Enter product name"}
        customerId={searchItem}
        searchCustomer={handleSearch}
        name="Search product"
        type="text"
      />
      {searchResults.length > 1 && (
        <div className="search-results-dropdown">
          <label>Select Product:</label>
          <select
            value={selectedProduct || ""}
            onChange={(e) => handleProductSelect(e.target.value)}
          >
            <option value="" disabled>
              Select a product
            </option>
            {searchResults.map((product) => (
              <option key={product._id} value={product._id}>
                {product.product_name}
              </option>
            ))}
          </select>
        </div>
      )}
      <form className="bg-secondary" onSubmit={handleSubmit}>
        <Row>
          {inputs.map((input) => (
            <React.Fragment key={`${input.id}_${input.code}`}>
              <Col md={6} xs={12} className="input-field">
                {input.name === "product_code" ? (
                  <Form.Group
                    controlId="productCodeDropdown"
                    style={{ width: "80%" }}
                  >
                    <Form.Label>{input.label}</Form.Label>
                    <Form.Control
                      as="select"
                      value={values[input.name]}
                      onChange={(e) =>
                        onChange({
                          target: { name: input.name, value: e.target.value },
                        })
                      }
                    >
                      {productCodes.map((product) => (
                        <option key={product.code} value={product.code}>
                          {product.code}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                )}
              </Col>
            </React.Fragment>
          ))}
        </Row>
        <Row>
          <Col>
            <Container className="product-code-container">
              <Row>
                {renderProductCodeFields()}
                <Col className="text-end">
                  <Button type="button" onClick={handleAddProductCode}>
                    Add Product Code
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <Button type="reset" onClick={reset}>
              Reset
            </Button>
          </Col>
          <Col className="text-start">
            <Button type="submit">{props.buttonName}</Button>
          </Col>
        </Row>
      </form>
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </Container>
  );
};

export default UpdateProduct;
