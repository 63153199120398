import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
const colors = [
  "#FF6633",
  "#FF33FF",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#FFB399",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];
const StackedBarCharts = (props) => {
  return (
    <>
      <BarChart
        width={props.width}
        height={props.height}
        data={props.data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={props.datakey}
          label={{
            value: `${props.value.xaxis}`,
            offset: -2,
            position: `${props.valuePosition.xaxis}`,
          }}
        />
        <YAxis
          label={{
            value: `${props.value.yaxis}`,
            angle: -90,
            position: `${props.valuePosition.yaxis}`,
          }}
        />
        <Tooltip />
        <Legend />
        {props.products.map((element, index) => {
          return (
            <Bar
              barSize={50}
              key={element}
              dataKey={element}
              stackId="a"
              fill={colors[index]}
            >
              <LabelList
                dataKey={element}
                position="inside"
                angle="0"
                fill="#000000"
              />
            </Bar>
          );
        })}
      </BarChart>
    </>
  );
};
export default StackedBarCharts;
