import React, { useEffect } from "react";
import { getData } from "../../../api/API";
import { useState } from "react";
import { days } from "./MonthlyReport";
import { Calendar2CheckFill, CurrencyRupee } from "react-bootstrap-icons";
import LoadingSpinner from "../LoadingSpinner";
const month = {
  1: "jan",
  2: "feb",
  3: "march",
  4: "april",
  5: "may",
  6: "june",
  7: "july",
  8: "agust",
  9: "sept",
  10: "oct",
  11: "nov",
  12: "dec",
};
const LatestExpense = () => {
  const [data, setData] = useState({ data: "", date: "" });
  useEffect(() => {
    (async () => {
      const res = await getData("expenses/latestexpense");
      console.log(res);
      if (res.data) {
        let arr = res.data;

        if (arr.length > 0) {
          setData({ data: arr[0], date: new Date(arr[0].create_date) });
        }
        console.log(arr[0]);
      } else {
        console.log(res);
      }
    })();
  }, []);
  return (
    <>
      {!data.data && <LoadingSpinner />}
      {data.data && (
        <>
          <div style={{ height: "100%" }}>
            <div>
              <h4 className="text-center text-primary">Last Expense</h4>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ height: "90%" }}
            >
              <div className="card  border-0">
                <div className="bg-opacity-100 bg-white shadow border-white rounded">
                  <div className="card-body ">
                    <div className="d-flex  align-items-center justify-content-center ">
                      <CurrencyRupee size={25} />
                      <span>
                        <h5>{data.data.total_amount}</h5>
                      </span>
                    </div>

                    <div className="d-flex  align-items-center justify-content-center py-1">
                      <Calendar2CheckFill size={20} />
                      {" " +
                        days[data.data.day] +
                        " " +
                        month[parseInt(data.data.month)] +
                        " " +
                        data.data.date +
                        " " +
                        data.data.year}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LatestExpense;
