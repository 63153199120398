import "./App.css";
import Login from "./components/modules/Login";
import { createData } from "./api/API";
import { Route, Routes, useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import SignUpForm from "./components/modules/SignUpForm";
import Navigation from "./components/modules/Navigation";
import UpdateExpense from "./components/modules/UpdateExpense";
import AddItems from "./components/modules/AddItems";
import { Col, Container, Row } from "react-bootstrap";
import Expense from "./components/modules/Expense";
import UpdateItem from "./components/modules/UpdateItem";
import BBSerachCustomerToUpdate from "./components/modules/bbcustomer/BBSerachCustomerToUpdate";

import { useEffect, useState } from "react";
import Homepage from "./views/Homepage";
import MonthlyReport from "./components/modules/reports/MonthlyReport";
import * as Icon from "react-bootstrap-icons";
import YearlyReport from "./components/modules/reports/YearlyReport";
import ItemsMonthlyReport from "./components/modules/reports/ItemsMonthlyReport";
import BitByteRewards from "./views/BitByteRewards";
import BBAddCustomer from "./components/modules/bbcustomer/BBAddCustomer";
import LoadingSpinner from "./components/modules/LoadingSpinner";
import ToastMessage from "./components/modules/ToastMessage";
import GetAllCustomer from "./components/modules/bbcustomer/GetAllCustomer";
import AddRetailer from "./components/modules/bbretailer/AddRetailer";
import Retailer from "./views/Retailer";
import BBRetailerUpdate from "./components/modules/bbretailer/BBRetailerUpdate";
import GetAllRetailer from "./components/modules/bbretailer/GetAllRetailer";

import UpdateCustomerInfo from "./components/modules/bbcustomer/UpdateCustomerInfo";
import SearchRetailer from "./components/modules/invoice/SearchRetailer";
import GetAllInvoice from "./components/modules/invoice/GetAllInvoice";
import DownloadInvoice from "./components/modules/DownloadInvoice";
import AddProduct from "./components/modules/bbretailer/AddProduct";
import BulkMessage from "./components/modules/bulkmessage/BulkMessage";
import SearchIndivisualRetailer from "./components/modules/invoice/SearchIndivisualRetailer";
import Invoice from "./views/Invoice";
import UpdateProduct from "./components/modules/bbretailer/UpdateProduct";
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
const App = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const onLogin = async (data) => {
    try {
      const res = await createData("users/login", data);

      if (res.data.status === "success") {
        document.cookie = `user_id=${res.data.authUser}; expires=${new Date(
          new Date().setDate(new Date().getDate() + 7)
        ).toUTCString()}`;
        setIsLoading(true);
        setShowToast({
          flag: true,
          message: "Logged in Successfully",
          bg: "success",
        });
        navigate("/");
        setLoggedIn(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        return res.data;
      } else {
        return res.data;
      }
    } catch (e) {
      console.log("error");
    }
  };
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (document.cookie.match("user_id")) {
        const res = await createData("users/verifytoken", {
          user_id: getCookie("user_id"),
        });
        if (res.data.status === "success") {
          setLoggedIn(true);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          console.log("invalid user please login");
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
  }, []);
  const onLogout = () => {
    document.cookie = `user_id=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    setLoggedIn(false);
    setShowToast({
      flag: true,
      message: "Logged out Successfully",
      bg: "success",
    });
    navigate("/");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} id="abc1">
            <Navigation loggedIn={loggedIn} onLogout={onLogout} />
          </Col>
          <Col md={10} id="abc2">
            {loggedIn && (
              <div className="d-flex justify-content-between">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="bg-primary"
                >
                  <Icon.ArrowLeft />
                </button>
                <button
                  onClick={() => {
                    navigate(1);
                  }}
                >
                  <Icon.ArrowRight />
                </button>
              </div>
            )}
            <Routes>
              <Route
                path="/"
                element={
                  isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <Homepage loggedIn={loggedIn} onLogin={onLogin} />
                  )
                }
              ></Route>
              <Route path="/signup" element={<SignUpForm />} />
              <Route path="/login" element={<Login onLogin={onLogin} />} />
              <Route path="/additems" element={<AddItems />} />
              <Route path="/addexpense" element={<Expense />}></Route>
              <Route path="/updateexpense" element={<UpdateExpense />}></Route>
              <Route path="/addpoints" element={<BBSerachCustomerToUpdate />} />
              <Route path="/updateexpense" element={<UpdateExpense />} />
              {<Route path="/addexpense" element={<Expense />} />}
              <Route
                path="/addcustomer"
                element={<BBAddCustomer buttonName={"Register"} />}
              />
              <Route path="/updatecustomer" element={<UpdateCustomerInfo />} />
              <Route path="/items" element={<UpdateItem />} />
              <Route path="/reportyearly" element={<YearlyReport />}></Route>
              <Route
                path="/reportbyitem"
                element={<ItemsMonthlyReport />}
              ></Route>
              <Route path="/reportmonthly" element={<MonthlyReport />}></Route>
              <Route
                path="/bitbyterewards"
                element={<BitByteRewards />}
              ></Route>
              <Route
                path="/getallcustomers"
                element={<GetAllCustomer />}
              ></Route>
              <Route path="/createinvoice" element={<SearchRetailer />} />
              <Route
                path="/retailerinfo"
                element={<SearchRetailer search="information" />}
              />

              <Route
                path="/addretailer"
                element={<AddRetailer buttonName="Add Retailer" />}
              />
              <Route
                path="/retailer"
                element={<Retailer buttonName="Retailer" />}
              />
              <Route path="/invoice" element={<Invoice />}></Route>
              <Route
                path="/updateretailer"
                element={<BBRetailerUpdate />}
              ></Route>
              <Route
                path="/getallretailers"
                element={<GetAllRetailer />}
              ></Route>
              <Route path="/getallinvoices" element={<GetAllInvoice />}></Route>
              <Route
                path="/downloadinvoice"
                element={<DownloadInvoice />}
              ></Route>
              <Route
                path="/addproduct"
                element={<AddProduct buttonName="Add Products" />}
              />
              <Route
                path="/updateProduct"
                element={<UpdateProduct buttonName="Update products"/>}
                />
              <Route
                path="/searchindivisualretailer"
                element={
                  <SearchIndivisualRetailer buttonName="SearchIndivisualRetailer" />
                }
              />
              <Route path="/bulkmessage" element={<BulkMessage />} />
            </Routes>
          </Col>
        </Row>
      </Container>
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </>
  );
};

export default App;
