import { useEffect } from "react";
import { getDataByQuery, updateData } from "../../../api/API";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/BBCustomerUpdate.css";
import StackedBarCharts from "../../charts/StackedBarCharts";

const SearchIndivisualRetailer = (props) => {
  const [stackedBarChartData, setStackedBarChartData] = useState({
    barChartData: "",
    products: "",
  });
  useEffect(() => {
    (async () => {
      if (props.retailer_phonenumber) {
        const resp = await getDataByQuery("retailerinvoice/lastNdaysinvoices", {
          retailer_id: props.retailer_phonenumber,
        });
        setStackedBarChartData({
          barChartData: resp.barChartData.length > 0 ? resp.barChartData : "",
          products: resp.products.length > 0 ? resp.products : "",
        });
      }
    })();
  }, [props]);
  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <div className="customer-update-container bg-light">
              <div>Name: {props.name}</div>
              <div className="customer-update bg-secondary text-light">
                <div>Name: {props.name}</div>
                <div>phonenumber: {props.retailer_phonenumber}</div>
                <div>GST: {props.gstin}</div>
                <div>
                  Address:{" "}
                  {props.address.street ? props.address.street + "," : ""}
                  {props.address.city ? props.address.city + "," : ""}
                  {props.address.pincode ? props.address.pincode + "," : ""}
                  {props.address.state ? props.address.state + "" : ""}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {stackedBarChartData.barChartData && stackedBarChartData.products ? (
        <>
          <StackedBarCharts
            data={stackedBarChartData.barChartData}
            products={stackedBarChartData.products}
            datakey="date"
            width={1000}
            height={450}
            value={{ xaxis: "Date➡️", yaxis: "product in kg ➡️" }}
            valuePosition={{
              xaxis: "insideBottom",
              yaxis: "insideLeft",
              bar: "inside",
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SearchIndivisualRetailer;
