import React, { useEffect, useState } from "react";
import { getDataByQuery } from "../../../api/API";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReportPage from "../../../views/ReportPage";
import LoadingSpinner from "../LoadingSpinner";
export const month = [
  { monthNum: 1, month: "jan" },
  { monthNum: 2, month: "feb" },
  { monthNum: 3, month: "march" },
  { monthNum: 4, month: "april" },
  { monthNum: 5, month: "may" },
  { monthNum: 6, month: "june" },
  { monthNum: 7, month: "july" },
  { monthNum: 8, month: "agust" },
  { monthNum: 9, month: "sept" },
  { monthNum: 10, month: "oct" },
  { monthNum: 11, month: "nov" },
  { monthNum: 12, month: "dec" },
];
export const days = {
  1: "Sun",
  2: "Mon",
  3: "Tue",
  4: "Wed",
  5: "Thu",
  6: "Fri",
  7: "Sat",
};

const MonthlyReport = () => {
  const [monthAndYear, setMonthandYear] = useState({ month: "", year: "" });
  const [data, setData] = useState({ data: [], message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [screenDimension, setScreenDimension] = useState({
    width: 0,
    height: 0,
  });
  const setDate = (type, value) => {
    if (type === "month") {
      setMonthandYear({ month: parseInt(value), year: monthAndYear.year });
    } else {
      setMonthandYear({ month: monthAndYear.month, year: parseInt(value) });
    }
  };
  useEffect(() => {
    if (monthAndYear.year !== "" && monthAndYear.month !== "") {
      (async () => {
        // const res = await getData("expenses/getexpensebymonth");
        // console.log(res);
        setData({ data: [], message: "" });
        setIsLoading(true);
        const res = await getDataByQuery(
          "expenses/getexpensebymonth",
          monthAndYear
        );
        console.log(res);
        if (res.data.length > 0) {
          let arrayForReport = [];
          res.data.map((e) => {
            let obj = {};
            obj["totalAmount"] = e.totalAmount;
            obj["date"] = e.date + "," + days[e.day];
            console.log(days[e.day]);
            arrayForReport.push(obj);
            return "";
          });
          console.log(arrayForReport);
          setData({ data: arrayForReport, message: "" });
        } else {
          setData({ data: [], message: "Data is Not Available" });
        }
        setIsLoading(false);
      })();
    }
  }, [monthAndYear]);
  useEffect(() => {
    const width = window.screen.width;
    const height = window.screen.height;
    console.log(width, height);
    if (width < 768 && height > width) {
      setScreenDimension({ width: width, height: height / 2 });
    } else if (width < 768 && width > height) {
      setScreenDimension({ width: width, height: height - 100 });
    } else {
      setScreenDimension({ width: width / 1.25, height: height - 250 });
    }
  }, []);
  return (
    <>
      <ReportPage />
      <div className="text-center">
        <select
          onChange={(e) => {
            setDate("month", e.target.value);
          }}
          className="bg-secondary text-white rounded"
        >
          <option value="none" key="none" hidden>
            select month
          </option>
          {month.map((e) => {
            return (
              <React.Fragment key={e.month}>
                <option value={e.monthNum} key={e.monthNum}>
                  {e.month}
                </option>
              </React.Fragment>
            );
          })}
        </select>
        <select
          name="year"
          id="year"
          onChange={(e) => {
            setDate("year", e.target.value);
          }}
          className="bg-secondary text-white rounded"
        >
          <option value="" key="" hidden>
            select year
          </option>
          <option value="2023" key="2023">
            2023
          </option>
          <option value="2024" key="2024">
            2024
          </option>
          <option value="2025" key="2025">
            2025
          </option>
        </select>
        {isLoading && <LoadingSpinner />}
        {data.data.length > 0 ? (
          <AreaChart
            width={screenDimension.width}
            height={screenDimension.height - 20}
            data={data.data}
            margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              label={{
                value: `${month[monthAndYear.month - 1].month}`,
                offset: 3,
                position: "insideBottom",
              }}
            />
            <YAxis
              label={{
                value: "Total Expense",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              name="Total Expense"
              type="monotone"
              dataKey="totalAmount"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        ) : (
          <h1>{data.message}</h1>
        )}
      </div>
    </>
  );
};

export default MonthlyReport;
