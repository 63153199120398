import React from "react";

const BBSerachCustomer = (props) => {
  return (
    <>
      <div className="search-customer-input-container">
        <input
          type={props.type ? props.type : "text"}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.customerId(e);
              props.searchCustomer();
            }
          }}
          onChange={(e) => props.customerId(e)}
          placeholder={props.placeholder || "Enter customer ID"}
          className="search-customer-input bg-light border rounded"
        />
        <button onClick={props.searchCustomer} className="btn btn-primary">
          {props.name || "Search"}
        </button>
      </div>
    </>
  );
};

export default BBSerachCustomer;
