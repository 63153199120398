import axios from "axios";
axios.defaults.withCredentials = true;
const baseURL = "https://services.springeveningpl.com/api/";
// const baseURL = "http://127.0.0.1:3001/api/";
//http://127.0.0.1:3001/api/
/*get all users,expenses,items*/
export const getData = async (service) => {
  try {
    const response = await axios.get(`${baseURL}${service}`);
    return response.data;
  } catch (e) {
    return "can't get";
  }
};
export const getDataByID = async (service, id) => {
  try {
    const response = await axios.get(`${baseURL}${service}/${id}`);
    return response.data;
  } catch (e) {
    return "can't get";
  }
};
export const createData = async (service, data) => {
  try {
    const create = await axios.post(`${baseURL}${service}`, data);

    return create;
  } catch (err) {
    return err.response;
  }
};
export const updateData = async (service, id, updateData) => {
  try {
    const create = await axios.put(`${baseURL}${service}/${id}`, updateData);
    return create;
  } catch (err) {
    return err.response;
  }
};
export const deleteData = async (service, id) => {
  const response = await axios.delete(`${baseURL}${service}/${id}`);
  return response;
};
export const getDataByQuery = async (service, data) => {
  try {
    const res = await axios.get(`${baseURL}${service}`, {
      params: data,
    });
    return res.data;
  } catch (err) {}
};
