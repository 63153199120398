import React from "react";
import Login from "../components/modules/Login";
import { useEffect } from "react";
import { getDataByID } from "../api/API";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import LastSevenExpenseReport from "../components/modules/reports/LastSevenExpenseReport";
import LatestExpense from "../components/modules/reports/LatestExpense";
import LoyaltyReport from "../components/modules/reports/LoyaltyReport";
import { getCookie } from "../App";
import AddRetailer from "../components/modules/bbretailer/AddRetailer";

const Homepage = (props) => {
  const [user, setUser] = useState("");
  useEffect(() => {
    if (user === "") {
      (async () => {
        const user_id = getCookie("user_id");
        if (user_id) {
          (async () => {
            const res = await getDataByID("users", user_id);
            setUser(res.data);
          })();
        }
      })();
    }
  }, [props.loggedIn, user]);
  return (
    <>
      <Container>
        {props.loggedIn ? (
          <Row className="text-center">
            <h1>Welcome {user.user_name}</h1>
            <Row className="d-flex justify-content-center">
              <Col md={5} className="border p-0">
                <LoyaltyReport />
              </Col>
              <Col md={2} className="border p-0">
                <LatestExpense />
              </Col>
              <Col md={5} className="border p-0">
                <LastSevenExpenseReport />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <div className="p-3">
                  <Button className="bg-primary border-0">
                    <NavLink to={"/addpoints"} className={"text-white"}>
                      Add Points
                    </NavLink>
                  </Button>
                </div>
                <div className="p-3">
                  <Button className="bg-primary border-0">
                    <NavLink to={"/addexpense"} className={"text-white"}>
                      Add Expense
                    </NavLink>
                  </Button>
                </div>
                <div className="p-3">
                  <Button className="bg-primary border-0">
                    <NavLink to={"/reportmonthly"} className={"text-white"}>
                      Daily Expense
                    </NavLink>
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        ) : (
          <div>
            <Login onLogin={props.onLogin} />
          </div>
        )}
      </Container>
    </>
  );
};

export default Homepage;
