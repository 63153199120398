import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/ExpenseForm.css";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { getCookie } from "../../App";
import ToastMessage from "./ToastMessage";
import { getData } from "../../api/API";

const ExpenseForm = (props) => {
  const [inputFields, setInputFields] = useState([]);
  const [total, setTotal] = useState(0);
  const [item_category, setItemCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });

  const handleFormChange = (index, event) => {
    let data = [...inputFields];

    if (event.target.name === "quantity") {
      data[index]["quantity"] = event.target.value;
      setQuantity(event.target.value);
    } else {
      if (event.target.value !== "") {
        data[index][event.target.name] = parseFloat(event.target.value);
      } else {
        data[index][event.target.name] = "";
      }
      setInputFields(data);
    }
  };

  const submit = async (e) => {
    setIsLoading(true);
    setDisable(true);
    e.preventDefault();

    const result = inputFields.filter((element) => {
      return element.amount > 0 && element.amount !== "";
    });

    if (result.length > 0) {
      const date = new Date();
      const create_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      const dateTime = { create_date: date.toISOString(), create_time };
      let expenseObject = {
        user_id: getCookie("user_id"),
        items: result.map((item) => ({
          ...item,
          quantity: item.quantity,
        })),
        total_amount: total,
        total_items: result.length,
        ...dateTime,
      };
      if (props.saveButton === "update") {
        expenseObject = {
          user_id: getCookie("user_id"),
          items: result.map((item) => ({
            ...item,
            quantity: item.quantity,
          })),
          total_amount: total,
          total_items: result.length,
        };
      }
      const res = await props.createExpense(expenseObject);

      if (res && res.data && res.data.status === "success") {
        let arr = inputFields.map((e) => ({
          ...e,
          [Object.keys(e)[0]]: "",
          quantity: "",
        }));

        setIsLoading(false);
        setShowToast({
          flag: true,
          message: "Expense Data added Successfully",
          bg: "success",
        });
        setInputFields(arr);
        setDisable(false);
        setQuantity("");
      } else {
        console.log(res && res.data ? res.data : res);
      }
    } else {
      setIsLoading(false);
      setShowToast({
        flag: true,
        message: "At least one entry is needed",
        bg: "warning",
      });
      setDisable(false);
    }
  };

  useEffect(() => {
    setInputFields(props.initialData);
  }, [props.initialData]);

  useEffect(() => {
    const result = inputFields.filter((element) => {
      return element.amount !== "";
    });

    const sum = result.reduce((accumulator, currentElement) => {
      return (accumulator += currentElement.amount);
    }, 0);
    setTotal(sum);
  }, [inputFields]);
  const reset = () => {
    let arr = inputFields.map((e) => ({
      ...e,
      [Object.keys(e)[0]]: "",
      quantity: "",
    }));
    setInputFields(arr);
    setQuantity("");
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getData("categories"); // Used for fetching categories from API
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    })();
  }, []);
  const onSelectCategory = (category) => {
    setItemCategory(category);
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {inputFields.length > 0 && isLoading === false && (
        <>
          <div className="container" style={{ width: "80%" }}>
            <div className="select-item-category-block">
              <div
                className="select-items-label"
                style={{ marginRight: "8px" }}
              >
                <label className="text-black">Select item category:</label>
              </div>
              <div className="category-input" style={{ width: "28%" }}>
                <select
                  value={item_category}
                  onChange={(e) => onSelectCategory(e.target.value)}
                  required
                >
                  {/* <option value="" disabled>
                  Select item category
                </option> */}
                  {categories.map((category) => (
                    <option key={category.id} value={category.categoryName}>
                      {category.categoryName}
                    </option>
                  ))}
                  <option value="all">all</option>
                </select>
              </div>
            </div>

            <h1>total:{total}</h1>
            <form onSubmit={submit}>
              <Container>
                <Row>
                  {inputFields.map((input, index) => {
                    const keys = Object.keys(input);
                    // Check if the item's category matches the selected category
                    if (
                      item_category === "" ||
                      item_category === "all" ||
                      (item_category !== "" &&
                        input.item_category === item_category)
                    ) {
                      return (
                        <Col key={index} md={4} xs={12} className="pb-3">
                          <div className="card-container bg-light">
                            <div className="item-image-container">
                              <img
                                src={input.item_image}
                                alt=""
                                width={"150px"}
                              />
                            </div>
                            <div className="item-label">
                              <label>
                                <strong>{input.name}</strong>
                              </label>
                            </div>
                            <div className="item-info">
                              <div className="item-input">
                                <label className="text-black">Price:</label>
                                <input
                                  type="number"
                                  name={keys[0]}
                                  placeholder="₹"
                                  value={input[keys[0]]}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  onWheel={(e) => {
                                    return e.target.blur();
                                  }}
                                />
                              </div>
                              <div className="item-input-quantity">
                                <label className="text-black">Qty:</label>
                                <input
                                  type="text"
                                  name="quantity"
                                  value={input["quantity"]}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  onWheel={(e) => {
                                    return e.target.blur();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                    return null;
                  })}
                  <Col
                    md={4}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <button className="btn btn-primary ">
                        <NavLink to={"/additems"}>
                          <strong className="text-primary text-white">
                            Add Item
                          </strong>
                        </NavLink>
                      </button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <div className="buttons">
                    <button
                      type="reset"
                      onClick={reset}
                      className="ui-btn border-0 bg-info text-white"
                    >
                      reset
                    </button>
                    <button
                      type="submit"
                      onClick={submit}
                      className="ui-btn border-0  bg-info text-white"
                      disabled={disable}
                    >
                      {props.saveButton}
                    </button>
                  </div>
                </Row>
              </Container>
            </form>
          </div>
        </>
      )}
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </>
  );
};

export default ExpenseForm;
