import React, { useEffect, useState } from "react";
import { getData } from "../../../api/API";
import { days } from "./MonthlyReport";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LoadingSpinner from "../LoadingSpinner";
const LastSevenExpenseReport = () => {
  const [data, setData] = useState({ data: [] });
  useEffect(() => {
    (async () => {
      const res = await getData("expenses/lastsevendayexpense");
      if (res.data) {
        let arr = res.data;
        if (arr.length > 0) {
          const objArray = arr.map((e) => {
            let obj = { total: e.total };
            obj["date"] = e.date + " " + days[e.day];
            return obj;
          });
          console.log(objArray);
          setData({ data: objArray });
        } else {
          console.log(res.data);
        }
      } else {
        console.log(res);
      }
    })();
  }, []);
  return (
    <>
      {data.data.length < 1 && <LoadingSpinner />}
      {data.data.length > 0 && (
        <>
          <h4 className="text-center text-primary">Last Seven Days Expense</h4>
          <BarChart width={485} height={300} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{
                value: "last 7 days",
                offset: -2,
                position: "insideBottom",
              }}
            />
            <YAxis
              label={{
                value: "Expense Amount",
                angle: -90,
                position: "insideBottomLeft",
              }}
            />
            <Tooltip />
            <Legend verticalAlign={"bottom"} height={25} />
            <Bar
              dataKey="total"
              fill="#82ca9d"
              label={{
                fill: "purple",
                angle: -80,
                position: "insideLeft",
              }}
              barSize={20}
              animationBegin={1500}
              animationDuration={1500}
            />
          </BarChart>
        </>
      )}
    </>
  );
};

export default LastSevenExpenseReport;
