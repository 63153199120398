import React, { useState } from "react";
import { useEffect } from "react";
import { getData } from "../../../api/API";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  AreaChart,
  Legend,
} from "recharts";
import { days } from "./MonthlyReport";
import LoadingSpinner from "../LoadingSpinner";
const LoyaltyReport = () => {
  const [data, setData] = useState({ data: [] });
  useEffect(() => {
    (async () => {
      const res = await getData("bbcustomer/loyaltyreport");
      let arr = res.data;
      if (arr) {
        if (arr.length > 0) {
          arr = arr.map((e) => {
            return {
              ...e,
              date: days[e.day] + " " + e.date,
              customers: e.count,
            };
          });
          console.log(arr);
          setData({ data: arr });
        }
      }
    })();
  }, []);
  return (
    <>
      {data.data.length === 0 && <LoadingSpinner />}
      {data.data.length > 0 && (
        <>
          <h4 className="text-primary">Loyalty Report</h4>
          <AreaChart
            width={512}
            height={300}
            data={data.data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              label={{
                value: "date➡️",
                angle: 0,
                position: "insideBottom",
                offset: -2,
              }}
            />
            <YAxis
              label={{
                value: "customers➡️",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip offset={10} />
            <Legend verticalAlign="bottom" height={25} />
            <Area
              type="monotone"
              dataKey="customers"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
              baseLine={20}
              animationBegin={500}
              animationDuration={1000}
            />
          </AreaChart>
        </>
      )}
    </>
  );
};

export default LoyaltyReport;
