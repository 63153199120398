import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import RecentInvoice from "../components/modules/invoice/RecentInvoices";

const Retailer = () => {
  const navigate = useNavigate();
  return (
    <>
      <h2 className="text-center text-primary">Invoices</h2>
      <Container style={{ width: "80%" }}>
        <RecentInvoice />
        <Row className="py-5">
          <Col className="text-center p-0">
            <Button className="bg-primary border-0 ">
              <NavLink to={"/addretailer"} className="text-black">
                Add Retailer
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to={"/updateretailer"} className="text-black">
                Update Retailer
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to={"/retailerinfo"} className="text-black">
                Search Retailer
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate("/getallretailers");
              }}
            >
              Get All Retailers
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Retailer;
