import React from "react";

const Discount = (props) => {
  const { product_price, discount } = props;

  return (
    <>
      <input
        className="text-danger"
        type="number"
        onChange={(e) => {
          props.discount(
            { value: e.target.value },
            { type: props.product_name, product_price }
          );
        }}
      />
    </>
  );
};


export default Discount;
