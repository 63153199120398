import React from "react";
import BBRecentCustomers from "../components/modules/bbcustomer/BBRecentCustomers";
import { Button, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BitByteRewards = () => {
  return (
    <>
      <h2 className="text-center text-primary">Bit Byte Rewards</h2>
      <Container style={{ width: "80%" }}>
        <BBRecentCustomers />
        <Row className="p-5">
          <Col className="text-end">
            <Button className="bg-primary border-0">
              <NavLink to={"/addpoints"} className="text-white">
                Add Points
              </NavLink>
            </Button>
          </Col>
          <Col className="text-center p-0">
            <Button className="bg-primary border-0 ">
              <NavLink to={"/addcustomer"} className="text-white">
                Add Customer
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to="/updatecustomer" className="text-white">
                Update Customer
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to={"/getallcustomers"} className="text-white">
                Get All Customers
              </NavLink>
            </Button>
          </Col>
          <Col>
            <Button className="bg-primary border-0 rounded">
              <NavLink to={"/bulkmessage"} className="text-white">
                Send Bulk Message
              </NavLink>
            </Button>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                navigate("/getallcustomers");
              }}
            >
              Get All Customers
            </Button>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default BitByteRewards;
