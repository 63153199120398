import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { createData } from "../../../api/API";
import { Form, Radio, RadioGroup } from "rsuite";

const BulkMessage = () => {
  const [messagesTo, setMessagesTo] = useState("");

  const sendMessage = async () => {
    const resp = await createData("bulkmessage", { type: messagesTo });
    console.log(resp.data);
  };
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <Form.Group controlId="radioList">
            <RadioGroup
              name="radioList"
              value={messagesTo}
              onChange={setMessagesTo}
            >
              <Radio value="test_customers">send to test users</Radio>
              <Radio value="all_customers">
                send promotion message to all users
              </Radio>
              <Radio value="inactive_customers">
                send remailnder message to inactive users
              </Radio>
            </RadioGroup>
          </Form.Group>
          {messagesTo && (
            <div>
              <Button onClick={sendMessage}>send message</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BulkMessage;
