import React, { useEffect, useState } from "react";
import { getDataByQuery } from "../../../api/API";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReportPage from "../../../views/ReportPage";
import LoadingSpinner from "../LoadingSpinner";
const month = [
  { monthNum: 1, month: "jan" },
  { monthNum: 2, month: "feb" },
  { monthNum: 3, month: "march" },
  { monthNum: 4, month: "april" },
  { monthNum: 5, month: "may" },
  { monthNum: 6, month: "june" },
  { monthNum: 7, month: "july" },
  { monthNum: 8, month: "agust" },
  { monthNum: 9, month: "sept" },
  { monthNum: 10, month: "oct" },
  { monthNum: 11, month: "nov" },
  { monthNum: 12, month: "dec" },
];

const YearlyReport = () => {
  const [year, setYear] = useState("");
  const [yearlyData, setYearlyData] = useState({ data: [], message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const selectYear = (value) => {
    setYear(parseInt(value));
  };
  useEffect(() => {
    (async () => {
      setYearlyData({ data: [], message: "" });
      setIsLoading(true);
      if (year !== "") {
        const res = await getDataByQuery("expenses/getexpensebyyear", {
          year,
        });
        if (res.data)
          if (res.data.length > 0) {
            let arr = [];
            month.map((e, i) => {
              let count = 0;
              let obj = {};
              for (let index in res.data) {
                if (e.monthNum === res.data[index]._id) {
                  obj["month"] = e.month;
                  obj["monyhNumber"] = e.monthNum;
                  obj["monthlyExpense"] = res.data[index].totalExpense;
                  arr.push(obj);
                  count++;
                  break;
                }
              }
              if (count === 0) {
                obj["month"] = e.month;
                obj["monyhNumber"] = e.monthNum;
                obj["monthlyExpense"] = 0;
                arr.push(obj);
              }
              return "";
            });
            console.log(arr);
            setYearlyData({ data: arr, message: "" });
          } else {
            setYearlyData({ data: [], message: "Data is not available" });
            console.log("data is not available");
          }
      }
      setIsLoading(false);
    })();
  }, [year]);
  return (
    <>
      <ReportPage />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <select
            name="yearexpense"
            key={"yearexpense"}
            onChange={(e) => {
              selectYear(e.target.value);
            }}
            className="bg-secondary text-white rounded"
          >
            <option value="" key="" hidden>
              select year
            </option>
            <option value={2023} key="2023">
              2023
            </option>
            <option value={2024} key="2024">
              2024
            </option>{" "}
            <option value={2025} key="2025">
              2025
            </option>
            <option value={2026} key="2026">
              2026
            </option>
            <option value={2027} key="2027">
              2027
            </option>
          </select>
        </div>
        {isLoading && <LoadingSpinner />}
        {yearlyData.data.length > 0 ? (
          <div>
            <BarChart width={1536} height={650} data={yearlyData.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{
                  value: year,
                  offset: 3,
                  position: "insideBottom",
                }}
              />
              <YAxis
                label={{
                  value: "Expense Amount",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="monthlyExpense"
                fill="#82ca9d"
                label={{ fill: "blue", fontSize: 20 }}
                barSize={50}
              />
            </BarChart>
          </div>
        ) : (
          <h1>{yearlyData.message}</h1>
        )}
      </div>
    </>
  );
};

export default YearlyReport;
