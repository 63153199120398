import React from "react";
import { getData, updateData } from "../../api/API";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import ToastMessage from "./ToastMessage";
const UpdateItem = () => {
  const [items, setItems] = useState({ data: [] });
  const [updateitem, setUpdateItem] = useState("");
  const [flag, setFlag] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  useEffect(() => {
    (async () => {
      const res = await getData("items");
      console.log(res);
      if (res.data) {
        setItems({ data: res.data });
      } else {
      }
    })();
  }, []);
  const x = async () => {
    if (items.data.length < 1) {
      const res = await getData("items");
      console.log(res);
      setItems({ data: res.data });
    }
    setFlag(false);
  };
  const updateItem = (data) => {
    setUpdateItem(data);
  };
  const updateInDb = async (e) => {
    e.preventDefault();
    console.log(updateitem);
    items.data.splice(updateitem.index, 1, updateitem.data);
    setItems({ data: items.data });
    setFlag(false);
    const res = await updateData("items", updateitem.data._id, updateitem.data);
    console.log(res);
    if (res.data.status === "success") {
      setShowToast({
        flag: true,
        message: "Item Updated Successfully",
        bg: "success",
      });
    } else {
      setShowToast({
        flag: true,
        message: "Something Went Wrong",
        bg: "danger",
      });
    }
  };
  return (
    <>
      <div style={{ marginLeft: "7.5%", width: "85%" }}>
        <Container>
          <button className="btn btn-primary" onClick={x}>
            get all items
          </button>
          <Row>
            {items.data.length < 1 && <LoadingSpinner />}
            {items.data && flag === false && (
              <>
                {items.data.map((e, i) => {
                  return (
                    <React.Fragment key={e._id}>
                      <Col md={4} className="card">
                        <div className="card-body ">
                          <h5 className="card-title">{e.item_name}</h5>
                          <img
                            src={e.item_image}
                            width={80}
                            height={80}
                            alt=""
                          />
                          <p>{e.item_description}</p>
                          <p>
                            {e.item_active === true
                              ? "item is active"
                              : "item is inactive"}
                          </p>
                          <p>category:{e.item_category}</p>
                          <div className="text-end">
                            <Button
                              onClick={() => {
                                updateItem({ data: e, index: i });
                                setFlag(true);
                              }}
                              className="btn btn-primary"
                            >
                              edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}
                <Row>
                  <Col md={12} className="p-5 d-flex justify-content-center">
                    <div>
                      <button className="btn btn-primary">
                        <NavLink to={"/additems"}>
                          <strong className="text-white">add item</strong>
                        </NavLink>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </Container>
      </div>
      {updateitem && flag && (
        <div className="card" style={{ width: "50%" }}>
          <form className="card-body">
            <div className=" d-flex-column ">
              <div className="p-1">
                <label>item name:</label>
                <input
                  type="text"
                  value={updateitem.data.item_name}
                  onChange={(e) => {
                    setUpdateItem((itemInfo) => {
                      return {
                        ...itemInfo,
                        data: { ...itemInfo.data, item_name: e.target.value },
                      };
                    });
                  }}
                />
                <img
                  src={updateitem.data.item_image}
                  width={50}
                  height={50}
                  alt=""
                />
              </div>
              <div className="p-1">
                <label>image url:</label>
                <input
                  type="text"
                  value={updateitem.data.item_image}
                  onChange={(e) => {
                    setUpdateItem((itemInfo) => {
                      return {
                        ...itemInfo,
                        data: {
                          ...itemInfo.data,
                          item_image: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="p-1">
                <label>description:</label>
                <input
                  type="text"
                  value={updateitem.data.item_description}
                  onChange={(e) => {
                    setUpdateItem((itemInfo) => {
                      return {
                        ...itemInfo,
                        data: {
                          ...itemInfo.data,
                          item_description: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="p-1">
                <label>category:</label>
                <input
                  type="text"
                  value={updateitem.data.item_category}
                  onChange={(e) => {
                    setUpdateItem((itemInfo) => {
                      return {
                        ...itemInfo,
                        data: {
                          ...itemInfo.data,
                          item_category: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div className="p-1">
                <label>active/inactive:</label>
                <select
                  value={updateitem.data.item_active}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setUpdateItem((c) => {
                        return { ...c, data: { ...c.data, item_active: true } };
                      });
                    } else {
                      setUpdateItem((c) => {
                        return {
                          ...c,
                          data: { ...c.data, item_active: false },
                        };
                      });
                    }
                  }}
                >
                  <option value={true} key="active">
                    active
                  </option>
                  <option value={false} key="inactive">
                    deactive
                  </option>
                </select>
              </div>
            </div>

            <div className="text-end">
              <Button className="btn btn-primary" onClick={updateInDb}>
                update item
              </Button>
            </div>
          </form>
        </div>
      )}
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </>
  );
};

export default UpdateItem;
