import React from "react";
import { NavLink } from "react-router-dom";

const ExpensePage = () => {
  return (
    <>
      <div style={{ margin: "5px" }} className="d-flex justify-content-center">
        <div className="p-2">
          <button className="btn btn-primary">
            <NavLink className={"text-white"} to="/addexpense">
              <strong>Add Expense</strong>
            </NavLink>
          </button>
        </div>
        <div className="p-2">
          <button className="btn btn-primary">
            <NavLink className={"text-white"} to="/updateexpense">
              <strong>Update Expense</strong>
            </NavLink>
          </button>
        </div>
        <div className="p-2">
          <button className="btn btn-primary">
            <NavLink className={"text-white"} to="/items">
              <strong>Items</strong>
            </NavLink>
          </button>
        </div>
      </div>
    </>
  );
};

export default ExpensePage;
