import React, { useState } from "react";
import "../styles/Login.css";
import { useInput } from "../hook/useInput";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Login = (props) => {
  const [user_name, bindUserName] = useInput();
  const [user_password, bindUserPassword] = useInput();
  const [invalidUser, setInvalidUser] = useState("");
  const [invalidpassword, setInvalidPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const errorHandle = (id, borderColor) => {
    const element = document.getElementById(id);
    element.style.borderColor = borderColor;
  };
  const onLogin = async (e) => {
    e.preventDefault();
    setDisable(true);
    const res = await props.onLogin({ user_email: user_name, user_password });
    console.log(res);
    if (res) {
      if (res.error) {
        if (res.error === "invalid user") {
          errorHandle("username", "red");
          setInvalidUser("invalid user");
        } else if (res.error === "incorrect password") {
          errorHandle("password", "red");
          setInvalidPassword("enter a valid password");
        }
        setDisable(false);
      } else if (res.authUser) {
        localStorage.setItem("user_id", res.authUser);
      }
    }
  };
  return (
    <>
      <Container fluid className="login-container ">
        <div className="login-form px-2 bg-secondary sepl-primary">
          <div
            className="text-light"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h1>Login Here</h1>
          </div>
          <form onSubmit={onLogin}>
            <Row className="pt-2 ">
              <Col md={8}>
                <label htmlFor="username" className="text-light">
                  Phone number/email:
                </label>
              </Col>
              <Col xs={12} md={12}>
                <input
                  className="login-input "
                  type="text"
                  id="username"
                  {...bindUserName}
                  autoComplete="on"
                  placeholder="phonenumber or email"
                  onClick={() => {
                    errorHandle("username", "initial");
                    setInvalidUser("");
                  }}
                />
              </Col>
              <Col style={{ color: "red" }}>{invalidUser}</Col>
            </Row>
            <Row>
              <Col md={8}>
                <label htmlFor="password" className="text-light">
                  Passsword:
                </label>
              </Col>
              <Col xs={12} md={12}>
                <input
                  className="login-input "
                  type="password"
                  id="password"
                  required
                  {...bindUserPassword}
                  autoComplete="on"
                  placeholder="password"
                  onClick={() => {
                    errorHandle("password", "initial");
                    setInvalidPassword("");
                  }}
                />
              </Col>
              <Col style={{ color: "red" }}>{invalidpassword}</Col>
            </Row>
            <Row>
              <Col md={12}>
                <button
                  className="login-btn bg-primary my-3 py-2 text-white"
                  type="submit"
                  disabled={disable}
                >
                  Login
                </button>
              </Col>
            </Row>
            <Row>
              <Col className="py-3 text-light">
                <strong>
                  Don't have an account?
                  <NavLink to={"/signup"}> Signup/Register</NavLink>
                </strong>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Login;
