import React, { useEffect, useState } from "react";
import "../styles/AddItems.css";
import { useInput } from "../hook/useInput";
import { createData, getData } from "../../api/API"; 

const AddItems = (props) => {
  const [item_name, bindItemName, resetItemName] = useInput();
  const [item_image, bindItemImage, resetItemImage] = useInput();
  const [item_description, bindItemDescription, resetItemDescription] = useInput();
  const [item_category, setItemCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
   ( async () => {
      try {
        const response = await getData("categories"); // Used for fetching categories from API
        console.log(response)
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    })();

  }, []);

  const onLogin = async () => { 
    const data = {
      item_name,
      item_image,
      item_description,
      item_category,
    };
    const res = await createData("items", data);
    console.log(res);
    resetItemName();
    resetItemImage();
    resetItemDescription();
    setItemCategory('');
  };

  return (
    <>
      <div className="item-container ">
        <div className="item-card bg-secondary sepl-primary">
          <h1 className="text-light">Add Items</h1>

          <div className="item-name-block">
            <div className="items-label">
              <label className="text-light">Item Name:</label>
            </div>
            <div className="item-input ">
              <input
                type="name"
                {...bindItemName}
                placeholder="Enter item name"
              />
            </div>
          </div>
          <div className="item-image-block">
            <div className="items-label">
              <label className="text-light">Item Image:</label>
            </div>
            <div className="item-input">
              <input
                type="text"
                {...bindItemImage}
                s
                placeholder="Enter item image"
              />
            </div>
          </div>
          <div className="item-description-block">
            <div className="items-label">
              <label className="text-light">Item Description:</label>
            </div>
            <div className="item-input">
              <input
                type="text"
                {...bindItemDescription}
                placeholder="Enter item description"
              />
            </div>
          </div>

          <div className="item-category-block">
            <div className="items-label">
              <label className="text-light">Item Category:</label>
            </div>
            <div className="item-input">
              <select
                value={item_category}
                onChange={(e) => setItemCategory(e.target.value)}
                required
              >
                <option value="" disabled>Select item category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.categoryName}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="item-btns ">
            <button className="btn btn-primary" onClick={onLogin}>
              Reset
            </button>

            <button className="btn btn-primary" onClick={onLogin}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddItems;