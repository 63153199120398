// import React from "react";
import { useEffect } from "react";
import { updateData } from "../../../api/API";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/BBCustomerUpdate.css";
import Whatsapp from "../WhatsApp";
import LoadingSpinner from "../LoadingSpinner";
import ToastMessage from "../ToastMessage";

const BBCustomerUpdate = (props) => {
  const currentDate = new Date().toISOString();
  const [isLoading, setIsLoading] = useState(false);
  const [idliDosa, setIdliDosa] = useState({
    idli: 0,
    dosa: 0,
    currentSet: { total: 0 },
  });
  const [data, setData] = useState(props.data);
  const [newSet, setNewSet] = useState({
    expire_date: "",
    dateString: "",
  });
  const [count, setCount] = useState({
    freePackets: 0,
    eligibleForExtraPacket: 0,
  });
  const [selectedFreePackets, setSelectedFreePackets] = useState(0);
  const [currentSetTotal, setCurrentSetTotal] = useState({ initTotal: 0 });

  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  useEffect(() => {
    setData(props.data);
    setNewSet({
      expire_date: "",
      dateString: "",
    });

    if (props.data) {
      setCount((c) => {
        return {
          ...c,
          freePackets: props.data.currentSet.eligibleForExtraPacket,
          eligibleForExtraPacket: props.data.currentSet.eligibleForExtraPacket,
        };
      });
      setIdliDosa({
        idli: 0,
        dosa: 0,
        currentSet: { total: props.data.currentSet.total },
      });
      setCurrentSetTotal({ initTotal: props.data.currentSet.total });
    }
  }, [props.data]);
  useEffect(() => {
    if (idliDosa.currentSet.total > 0) {
      setCount((c) => {
        return {
          ...c,
          freePackets:
            count.eligibleForExtraPacket +
            Math.floor(idliDosa.currentSet.total / 10),
        };
      });
    }
  }, [idliDosa.currentSet.total, count.eligibleForExtraPacket]);
  const updateCustomer = async () => {
    const date = new Date();
    console.log(date);
    if (currentDate > data.expire_date) {
      console.log("if expired");
      const checkBox = document.getElementById("check");
      console.log(checkBox);
      if (checkBox) {
        if (checkBox.checked) {
          let x = "";
          if (currentDate > data.expire_date) {
            x = idliDosa.idli + idliDosa.dosa;
          } else {
            x = data.currentSet.total + idliDosa.idli + idliDosa.dosa;
          }
          let extraPck = 0;
          if (x >= 10) {
            extraPck = Math.floor(x / 10);
            x = x % 10;
          }

          const obj = {
            products: {
              idli: data.products.idli + idliDosa.idli,
              dosa: data.products.dosa + idliDosa.dosa,
            },
            updatedAt: new Date(date.getTime() + 330 * 60 * 1000),
            expire_date: newSet.expire_date,
            currentSet: {
              total: x,
              eligibleForExtraPacket: extraPck,
            },
            freePacketsGiven: data.freePacketsGiven + selectedFreePackets,
          };
          console.log(obj);
          const res = await updateData("bbcustomer", data._id, obj);
          document.getElementById("check").checked = false;
          console.log(res.data);
          setShowToast({
            flag: true,
            message: "Customer Data Updated Successfully",
            bg: "success",
          });
          setData({
            ...obj,
            _id: data._id,
            customer_id: data.customer_id,
            customer_phonenumber: data.customer_phonenumber,
            firstName: data.firstName,
            lastName: data.lastName,
          });
          setNewSet({
            expire_date: "",
            dateString: "",
          });
          setIdliDosa({
            idli: 0,
            dosa: 0,
            currentSet: {
              total: idliDosa.currentSet.total % 10,
            },
          });
          setCount((c) => {
            return {
              ...c,
              freePackets: obj.currentSet.eligibleForExtraPacket,
              eligibleForExtraPacket: obj.currentSet.eligibleForExtraPacket,
            };
          });
          setCurrentSetTotal({
            initTotal: idliDosa.currentSet.total % 10,
          });
          setSelectedFreePackets(0);
        } else {
          alert("Please select the checkbox to update the customer card.");
        }
      }
    } else {
      let x = data.currentSet.total + idliDosa.idli + idliDosa.dosa;

      if (x >= 10) {
        x = x % 10;
      }
      let expDate = data.expire_date;
      console.log(expDate);
      console.log(currentSetTotal.initTotal);
      if (
        (currentSetTotal.initTotal === 0 &&
          idliDosa.idli + idliDosa.dosa > 0) ||
        data.currentSet.total + idliDosa.idli + idliDosa.dosa > 10
      ) {
        const d = new Date();
        expDate = new Date(
          new Date(
            new Date(d.setDate(d.getDate() - 1)).setMonth(d.getMonth() + 2)
          ).getTime() +
            330 * 60 * 1000
        ).toISOString();
        console.log(expDate);
      }
      console.log(expDate);
      const _id = data._id;
      const obj = {
        products: {
          idli: data.products.idli + idliDosa.idli,
          dosa: data.products.dosa + idliDosa.dosa,
        },
        currentSet: {
          total: x,
          eligibleForExtraPacket: count.freePackets - selectedFreePackets,
        },
        updatedAt: new Date(date.getTime() + 330 * 60 * 1000),
        expire_date: expDate,
        freePacketsGiven: data.freePacketsGiven + selectedFreePackets,
      };
      const res = await updateData("bbcustomer", _id, obj);
      console.log(res.data);
      console.log({
        ...obj,
        _id,
        expire_date: data.expire_date,
        customer_id: data.customer_id,
      });
      setShowToast({
        flag: true,
        message: "Customer Data Updated Successfully",
        bg: "success",
      });
      setData({
        ...obj,
        _id,
        expire_date: expDate,
        customer_id: data.customer_id,
        customer_phonenumber: data.customer_phonenumber,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      setIdliDosa({
        idli: 0,
        dosa: 0,
        currentSet: {
          total: idliDosa.currentSet.total % 10,
        },
      });
      setCount((c) => {
        return {
          ...c,
          freePackets: obj.currentSet.eligibleForExtraPacket,
          eligibleForExtraPacket: obj.currentSet.eligibleForExtraPacket,
        };
      });
      setCurrentSetTotal({ initTotal: idliDosa.currentSet.total % 10 });
      setSelectedFreePackets(0);
    }
    setIsLoading(false);
  };
  const check = (e) => {
    const flag = document.getElementById("check").checked;
    if (flag) {
      const date = new Date();

      const expire_date = new Date(
        new Date(
          new Date(date.setDate(date.getDate() - 1)).setMonth(
            date.getMonth() + 2
          )
        ).getTime() +
          330 * 60 * 1000
      );
      setNewSet({
        expire_date: expire_date.toISOString(),
        dateString: expire_date.toDateString(),
      });
    } else {
      setNewSet({
        expire_date: "",
        dateString: "",
      });
    }
  };

  const incr = (action) => {
    if (action.type === "dosa") {
      setIdliDosa({
        ...idliDosa,
        dosa: idliDosa.dosa + 1,
        currentSet: {
          total: 1 + idliDosa.currentSet.total,
        },
      });
    } else if (action.type === "idli") {
      setIdliDosa({
        ...idliDosa,
        idli: idliDosa.idli + 1,
        currentSet: {
          total: 1 + idliDosa.currentSet.total,
        },
      });
    } else if (action.type === "freePackets") {
      if (selectedFreePackets < count.freePackets && selectedFreePackets >= 0) {
        setSelectedFreePackets(selectedFreePackets + 1);
      } else {
        console.log("not possible");
      }
    }
  };
  const decrement = (action) => {
    if (action.type === "dosa") {
      if (idliDosa.dosa !== 0) {
        setIdliDosa({
          ...idliDosa,
          dosa: idliDosa.dosa - 1,
          currentSet: {
            total: idliDosa.currentSet.total - 1,
          },
        });
      } else {
        console.log("not possible");
      }
    } else if (action.type === "idli") {
      if (idliDosa.idli !== 0) {
        setIdliDosa({
          ...idliDosa,
          idli: idliDosa.idli - 1,
          currentSet: {
            total: idliDosa.currentSet.total - 1,
          },
        });
      } else {
        console.log("not possible");
      }
    } else if (action.type === "freePackets") {
      if (selectedFreePackets > 0) {
        setSelectedFreePackets(selectedFreePackets - 1);
      } else {
        console.log("not possible");
      }
    }
  };
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {data && (
        <Container>
          <Row>
            <Col md={12} lg={12}>
              <div className="customer-update-container bg-light">
                {currentDate > data.expire_date && (
                  <p style={{ justifyContent: "center" }}>
                    <span className="text-danger">
                      Card is expired please update the card
                    </span>
                  </p>
                )}
                <div>
                  <strong>Customer id : {data.customer_id}</strong>
                  <div>
                    Current set started on :{" "}
                    {new Date(
                      new Date(
                        new Date(
                          new Date(
                            new Date(data.expire_date).getTime() +
                              330 * 60 * 1000
                          ).setMonth(
                            new Date(
                              new Date(data.expire_date).getTime() -
                                330 * 60 * 1000
                            ).getMonth() - 2
                          )
                        ).getTime() +
                          330 * 60 * 1000
                      ).setDate(new Date(data.expire_date).getDate() + 1)
                    ).toDateString()}
                  </div>
                </div>
                <div className="customer-update bg-secondary">
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item">
                      Name :
                      <strong> {data.firstName + " " + data.lastName}</strong>
                    </li>
                    <li className="list-group-item">
                      Phone Number :
                      <strong> {data.customer_phonenumber}</strong>
                    </li>
                  </ul>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item">
                      Total pcks purchased :
                      <div>
                        <strong className="px-2">
                          Idli :
                          <span className="badge bg-primary rounded-pill">
                            {data.products.idli}
                          </span>
                        </strong>

                        <strong className="px-2">
                          Dosa :
                          <span className="badge bg-primary rounded-pill">
                            {data.products.dosa}
                          </span>
                        </strong>
                      </div>
                    </li>
                    <li className="list-group-item">
                      Currentset Count : {idliDosa.currentSet.total}
                    </li>
                  </ul>
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item">
                      Today Purchased :
                      <div className="idli-dosa-update-btn-container">
                        <div className="idli-dosa-update-btn">
                          <button
                            onClick={() => {
                              decrement({ type: "idli" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            -
                          </button>

                          <strong className="px-1">
                            Idli : {idliDosa.idli}
                          </strong>
                          <button
                            onClick={() => {
                              incr({ type: "idli" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            +
                          </button>
                        </div>
                        <div className="idli-dosa-update-btn">
                          <button
                            onClick={() => {
                              decrement({ type: "dosa" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            -
                          </button>
                          <strong className="px-1">
                            Dosa : {idliDosa.dosa}
                          </strong>
                          <button
                            onClick={() => {
                              incr({ type: "dosa" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div className="customer-update-btn">
                    <button
                      onClick={updateCustomer}
                      className="customer-update-module-btns bg-success text-white"
                    >
                      Update Customer
                    </button>
                  </div>
                </div>
                {((count.freePackets > 0 && currentDate <= data.expire_date) ||
                  data.currentSet.total + idliDosa.dosa + idliDosa.idli >= 10 ||
                  currentDate > data.expire_date) && (
                  <div className="customer-update bg-secondary text-light">
                    {(data.currentSet.total + idliDosa.dosa + idliDosa.idli >
                      10 ||
                      currentDate > data.expire_date) && (
                      <div className="check-box-container">
                        <input type="checkbox" id="check" onChange={check} />
                        <span style={{ paddingLeft: "3px" }}>
                          Please select to start new set
                        </span>
                      </div>
                    )}
                    {newSet.expire_date && (
                      <span>New expiry date : {newSet.dateString}</span>
                    )}
                    {count.freePackets > 0 &&
                      currentDate <= data.expire_date && (
                        <>
                          <div>
                            Customer eligible to get
                            <strong>{" " + count.freePackets + " "}</strong>
                            Free packet.
                          </div>
                          <button
                            onClick={() => {
                              decrement({ type: "freePackets" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            -
                          </button>
                          Free packets provided:{selectedFreePackets}
                          <button
                            onClick={() => {
                              incr({ type: "freePackets" });
                            }}
                            className="badge bg-primary rounded-pill"
                          >
                            +
                          </button>
                        </>
                      )}
                  </div>
                )}
                <div className="expire-and-update-date ">
                  <div className="expire_date px-1 ">
                    {data.expire_date && (
                      <>
                        Expire Date :{" "}
                        {new Date(
                          new Date(data.expire_date).getTime() - 330 * 60 * 1000
                        )
                          .toString()
                          .split("GMT+0530 (India Standard Time)")}
                      </>
                    )}
                  </div>
                  <div className="update_date px-1">
                    Last Updated :{" "}
                    {new Date(
                      new Date(data.updatedAt).getTime() - 330 * 60 * 1000
                    )
                      .toString()
                      .split("GMT+0530 (India Standard Time)")}
                  </div>

                  <div>
                    <Whatsapp data={data} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </>
  );
};

export default BBCustomerUpdate;
