import React from "react";
import { useState } from "react";
import { getDataByQuery } from "../../../api/API";
import BBCustomerUpdate from "./BBCustomerUpdate";
import LoadingSpinner from "../LoadingSpinner";
import ToastMessage from "../ToastMessage";
import BBSerachCustomer from "./BBSearchCustomer";
import BarCharts from "../../charts/BarCharts";

const BBSerachCustomerToUpdate = () => {
  const [data, setData] = useState({ data: "", messages: [] });
  const [customer_id, setCustomer_ID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const [list, setList] = useState({ data: "", messages: "" });
  const customerId = async (e) => {
    setCustomer_ID(e.target.value);
  };

  const searchCustomer = async () => {
    setList({ data: "", messages: "" });
    setData({ data: "", messages: [] });
    setIsLoading(true);
    if (customer_id) {
      const res = await getDataByQuery("bbcustomer/id", {
        customer_id: customer_id,
      });
      if (res.data) {
        console.log(res.data);
        if (res.data.customer.length === 1) {
          if (res.data.messages && res.data.messages.length === 1) {
            setData({
              data: res.data.customer[0],
              messages: res.data.messages[0],
            });
          } else {
            setData({
              data: res.data.customer[0],
              messages: [],
            });
          }
        } else if (res.data.customer.length > 1) {
          setList({
            data: res.data.customer,
            messages: res.data.messages,
          });
        } else {
          setShowToast({
            flag: true,
            message: "enter a valid customer id or phone number",
            bg: "danger",
          });

          console.log("enter a valid customer id or phone number");
          setData({ data: "", messages: [] });
        }
      } else {
        setShowToast({
          flag: true,
          message: "Something went wrong.",
          bg: "danger",
        });
        console.log(res);
        setData({ data: "", messages: [] });
      }
    } else {
      setShowToast({
        flag: true,
        message: "please enter id or name",
        bg: "danger",
      });
    }
    setIsLoading(false);
  };
  const selectCustomer = (element, index) => {
    setData({ data: element, messages: list.messages[index] });
    setList({ data: "", messages: "" });
  };
  return (
    <>
      <BBSerachCustomer
        searchCustomer={searchCustomer}
        customerId={customerId}
        name={"search customer"}
        placeholder="enter customer ID or phonenumber"
        type="text"
      />

      {list.data && (
        <div
          style={{ width: "50%", marginLeft: 200 }}
          className="card bg-primary"
        >
          {list.data.map((e, index) => {
            return (
              <React.Fragment key={e._id}>
                <div
                  style={{ cursor: "pointer" }}
                  className="card-body py-1"
                  onClick={() => {
                    selectCustomer(e, index);
                  }}
                >{`Name: ${e.firstName} ${e.lastName}  Phone: ${e.customer_phonenumber}  Customer ID: ${e.customer_id}`}</div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {isLoading && <LoadingSpinner />}
      {data.data && <BBCustomerUpdate data={data.data} idli={0} dosa={0} />}
      {showToast.flag && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}

      {data.messages.length > 0 && (
        <BarCharts
          data={data.messages}
          width={485}
          height={250}
          value={{ xaxis: "Date", yaxis: "Packets" }}
          dataKey="date"
          valuePosition={{
            xaxis: "insideBottom",
            yaxis: "insideBottomLeft",
            bar: "inside",
          }}
          barDataKey="packets"
          barSize={20}
          animationBegin={1500}
          animationDuration={1500}
          heading="Recent activity of Customer"
          legend={{ height: 25, verticalAlign: "bottom" }}
          barFillColor="#82ca9d"
          barLabelFillColor="purple"
        />
      )}
    </>
  );
};

export default BBSerachCustomerToUpdate;
