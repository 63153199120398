import React, { useState } from "react";

import BBSerachCustomer from "./bbcustomer/BBSearchCustomer";
import { getDataByQuery } from "../../api/API";
import Invoice from "./invoice/Invoice";
import { Table } from "react-bootstrap";
import ToastMessage from "./ToastMessage";
import LoadingSpinner from "./LoadingSpinner";
const DownloadInvoice = () => {
  const [retailerID, setRetailerID] = useState("");
  const [invoiceInfo, setInvoiceInfo] = useState({ data: "" });
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const searchInvoice = async () => {
    setIsLoading(true);
    setInvoiceInfo("");

    const res = await getDataByQuery("retailerinvoice/invoicenumber", {
      invoiceNumber: retailerID,
    });
    if (res.status === "success") {
      if (res.data && res.data.length > 0) {
        const actualData = res.data[0];
        const dateString = new Date(actualData.date).toLocaleDateString(
          "en-GB"
        );
        const pdfFileName = `${actualData.retailer[0].name}_${actualData.invoice_number}_${dateString}`;
        const client = {
          company: actualData.retailer[0].name
            ? actualData.retailer[0].name
            : "",
          address: actualData.retailer[0].address.street
            ? actualData.retailer[0].address.street
            : "",
          zip: actualData.retailer[0].address.pincode
            ? ` ${actualData.retailer[0].address.pincode}`
            : "",
          city: actualData.retailer[0].address.city,
          country: actualData.retailer[0].address.state,
          custom1: `Phone: ${actualData.retailer[0].retailer_phonenumber}`, //phone number
          custom2: actualData.retailer[0].gstin
            ? `GSTIN: ${actualData.retailer[0].gstin}`
            : "", //gst number
        };
        const information = {
          number: actualData.invoice_number,
          date: dateString,
          totalAmount: actualData.totalAmount,
        };
        const products = actualData.products.map((e) => {
          console.log(e);
          delete e._id;
          delete e.product_id;
          e["tax-rate"] = 5;
          return e;
        });
        // console.log(client, information, products);
        setInvoiceInfo({
          data: { client, information, products, pdfFileName },
        });
      } else {
        console.log("not found");
        setShowToast({
          flag: true,
          message: "Not Found",
          bg: "danger",
        });
      }
    } else {
      console.log(res);
      setShowToast({
        flag: true,
        message: "Something went wrong",
        bg: "danger",
      });
    }
    setIsLoading(false);
  };
  const setInvoice = (e) => {
    setInvoiceInfo("");
    setRetailerID(e.target.value);
  };
  return (
    <>
      <div>
        <BBSerachCustomer
          searchCustomer={searchInvoice}
          customerId={setInvoice}
          name={"search invoice"}
          type="text"
          placeholder="Enter invoice number"
        />
      </div>

      {invoiceInfo.data ? (
        <>
          <div>
            Company Name: <strong>{invoiceInfo.data.client.company}</strong>
          </div>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceInfo.data.products.map((e, i) => {
                return (
                  <React.Fragment key={e.description}>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{e.description}</td>
                      <td>{e.quantity}</td>
                      <td>{e.price}</td>
                      <td>{parseFloat(e.quantity * e.price).toFixed(2)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr>
                <td></td> <td></td> <td></td>{" "}
                <td>
                  <strong>Grand Total</strong>
                </td>
                <td>{invoiceInfo.data.information.totalAmount}</td>
              </tr>
            </tbody>
          </Table>
          <Invoice data={invoiceInfo.data} />
        </>
      ) : null}
      {isLoading && <LoadingSpinner />}
      {showToast && (
        <ToastMessage showToast={showToast} setShowToast={setShowToast} />
      )}
    </>
  );
};

export default DownloadInvoice;
