import React, { useState } from "react";
import { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";

/**Hello <NAME OF THE CUSOMTER>
Thank you for shopping with us.
Number of packets bought during current program: <COUNT>
Expiry Date of the current program: <DATE>
Is customer eligible for free packet?
- Yes, Congratulations
- No, you still need to buy <count> packets within expiry date to be eligible.

For more information on the rewards program please visit https://bitbytefoods.com/
or contact 6363018056. */

const newline = "%0A";
const space = "%20";
const Whatsapp = (props) => {
  const [status, setStatus] = useState("");
  useEffect(() => {
    let message = "";
    if (props.data.currentSet.eligibleForExtraPacket > 0) {
      message = `Hello${space}${
        props.data.firstName + " " + props.data.lastName
      }${newline}Thank you for shopping with us.${newline}Number of packets bought during current program:${
        props.data.currentSet.total
      }${newline}Expiry Date of the current program:${new Date(
        props.data.expire_date
      ).toDateString()}${newline}Is customer eligible for free packet?${newline}Congratulations,you are eligible for ${
        props.data.currentSet.eligibleForExtraPacket
      } free packet${newline}${newline}For more information on the rewards program please visit https://bitbytefoods.com/ or contact 6363018056.`;
    } else {
      message = `Hello${space}${
        props.data.firstName + " " + props.data.lastName
      }${newline}Thank you for shopping with us.${newline}Number of packets bought during current program:${
        props.data.currentSet.total
      }${newline}Expiry Date of the current program:${new Date(
        props.data.expire_date
      ).toDateString()}${newline}Is customer eligible for free packet?${newline}you still need to buy ${
        10 - props.data.currentSet.total
      } packets within expiry date to be eligible.${newline}${newline}For more information on the rewards program please visit https://bitbytefoods.com/ or contact 6363018056.`;
    }

    setStatus(message);
  }, [props.data]);
  return (
    <>
      {status && (
        <div className="iconcontainer">
          <div className="icon-bar">
            <a
              href={`https://api.whatsapp.com/send?phone=91${props.data.customer_phonenumber}&text=${status}`}
              target="_blank"
              rel="noreferrer"
            >
              <span style={{ fontSize: "40px" }}>
                <Icon.Whatsapp></Icon.Whatsapp>
              </span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};
export default Whatsapp;
