import { useState } from "react";
import FormInput from "./FormInput";
import "../styles/SignupForm.css";
import { createData } from "../../api/API";
import { NavLink } from "react-router-dom";
import ToastMessage from "./ToastMessage";
const SignUpForm = () => {
  const [values, setValues] = useState({
    user_name: "",
    user_email: "",
    user_phonenumber: "",
    user_password: "",
    confirmPassword: "",
    user_city: "",
    user_role: "",
    user_department: "",
  });
  const [isSignUp, setSignUp] = useState(false);
  const [showToast, setShowToast] = useState({
    flag: false,
    message: "",
    bg: "",
  });
  const inputs = [
    {
      id: 1,
      name: "user_name",
      type: "text",
      placeholder: "enter your full name",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include any special character!",
      label: "Full Name",
      // pattern: "^[A-Za-z0-9]{5,35}$",
      required: true,
    },
    {
      id: 2,
      name: "user_email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "user_phonenumber",
      type: "text",
      placeholder: "enter contact number",
      errorMessage: "Enter the contact number",
      label: "Phone Number",
      pattern: "^[0-9]{10}$",
      maxLength: "10",
      required: true,
    },
    {
      id: 4,
      name: "user_password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 5,
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match!",
      label: "Confirm Password",
      pattern: values.user_password,
      required: true,
    },
    {
      id: 6,
      name: "user_city",
      type: "text",
      placeholder: "enter your city",
      errorMessage: "please enetr your city name",
      label: "City",
      required: true,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const user = Object.fromEntries(data);
    delete user.confirmPassword;
    if (user.user_role !== "admin") {
      user["active"] = false;
    } else {
      user["active"] = true;
    }
    console.log(user);
    console.log({ ...user });
    const res = await createData("users", { ...user });
    console.log(res);
    if (res.data.status === "success") {
      setShowToast({
        flag: true,
        message: "signup successfull",
        bg: "success",
      });
      setSignUp(true);
    }

    console.log(res);
    if (res.data.error) {
      const email = res.data.error.includes("user_email");
      email &&
        setShowToast({
          flag: true,
          message: "email already registered",
          bg: "danger",
        });
      const phno = res.data.error.includes("user_phonenumber");
      phno &&
        setShowToast({
          flag: true,
          message: "phno already registered",
          bg: "danger",
        });
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      {isSignUp ? (
        <>
          <h1>Registration completed successfully please login</h1>
          <NavLink to={"/login"}>
            <strong>login</strong>
          </NavLink>
        </>
      ) : (
        <div className="signup">
          <form className="sign-up-form bg-secondary" onSubmit={handleSubmit}>
            <h1 className="text-light">Register</h1>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div>
              <label className="signup-label text-light" htmlFor="role">
                Role:
              </label>
              <select name="user_role" onChange={onChange} required id="role">
                <option value={""} key={"role"} hidden>
                  Select role
                </option>
                <option value="distributor" key="dist">
                  Distributor
                </option>
                <option value="admin" key="admin">
                  Admin
                </option>
              </select>
            </div>
            <div>
              <label className="signup-label text-light" htmlFor="dept">
                Department:
              </label>
              <select
                id="dept"
                name="user_department"
                onChange={onChange}
                required
              >
                <option value={""} key={"dept"} hidden>
                  select department
                </option>
                <option value="XYZ" key="dist">
                  XYZ
                </option>
                <option value="ABC" key="admin">
                  ABC
                </option>
              </select>
            </div>
            <button className="signup-button bg-primary text-light">
              Submit
            </button>
            <strong className="text-light">
              Already have an account??<NavLink to={"/login"}>login</NavLink>
            </strong>
          </form>
        </div>
      )}
      {showToast.flag && (
        <ToastMessage setShowToast={setShowToast} showToast={showToast} />
      )}
    </>
  );
};

export default SignUpForm;
