import React from "react";
import { useEffect } from "react";
import { getData } from "../../../api/API";
import { useState } from "react";
// import EasyInvoiceSample from "./EasyInvoiceSample";
import InvoiceTable from "./InvoiceTable";
const AddInvoice = (props) => {
  const [products, setProducts] = useState([]);
  const [unitPrices, setUnitPrices] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    (async () => {
      const resp = await getData("products");
      if (resp.status === "success") {
        setProducts(resp.data);
        let obj = {};
        let id = {};
        resp.data.map((e) => {
          obj[e.product_name] = e.product_unit_price;
          id[e.product_name] = e._id;
          return "";
        });
        setUnitPrices(obj);
        setProductId(id);
      } else {
        console.log(resp);
      }
    })();
  }, []);
  return (
    <>
      <InvoiceTable
        {...props}
        productId={productId}
        products={products}
        unitPrices={unitPrices}
      />
    </>
  );
};

export default AddInvoice;
