import React, { useEffect, useState } from "react";
import { getData, getDataByQuery } from "../../../api/API";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReportPage from "../../../views/ReportPage";
import LoadingSpinner from "../LoadingSpinner";

const month = [
  { monthNum: 1, month: "jan" },
  { monthNum: 2, month: "feb" },
  { monthNum: 3, month: "march" },
  { monthNum: 4, month: "april" },
  { monthNum: 5, month: "may" },
  { monthNum: 6, month: "june" },
  { monthNum: 7, month: "july" },
  { monthNum: 8, month: "agust" },
  { monthNum: 9, month: "sept" },
  { monthNum: 10, month: "oct" },
  { monthNum: 11, month: "nav" },
  { monthNum: 12, month: "dec" },
];
const days = {
  1: "Sun",
  2: "Mon",
  3: "Tue",
  4: "Wed",
  5: "Thu",
  6: "Fri",
  7: "Sat",
};
const ItemsMonthlyReport = () => {
  const [data, setData] = useState({ data: [], itemName: "", message: "" });
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({
    item_id: "",
    month: "",
    year: "",
    itemName: "",
  });
  const [screenDimension, setScreenDimension] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    (async () => {
      if (
        selectedInfo.item_id !== "" &&
        selectedInfo.month !== "" &&
        selectedInfo.year !== ""
      ) {
        setData({ data: [], itemName: "", message: "" });
        setIsLoading(true);
        const res = await getDataByQuery(
          "expenses/getmonthlyexpensebyitem",
          selectedInfo
        );
        if (res.data) {
          if (res.data.length > 0) {
            const newObj = res.data.map((e) => {
              let obj = {};
              obj["_id"] = e._id + "\n" + days[e.day];
              obj["totalAmount"] = e.totalAmount;
              return obj;
            });
            let itemName = {};
            for (let i in items) {
              if (items[i]._id === selectedInfo.item_id) {
                itemName = items[i];
                break;
              }
            }
            setData({
              data: newObj,
              itemName: itemName.item_name,
              message: "",
            });
          } else {
            setData({
              data: [],
              itemName: "",
              message: "Data is Not Available",
            });
          }
        } else {
          console.log(res);
        }
      }
      setIsLoading(false);
    })();
  }, [selectedInfo, items]);
  useEffect(() => {
    (async () => {
      const res = await getData("items");
      if (res.data) setItems(res.data);
      else console.log("error while fetching items");
    })();
  }, []);
  useEffect(() => {
    const width = window.screen.width;
    const height = window.screen.height;
    console.log(width, height);
    if (width < 768) {
      setScreenDimension({ width: width, height: height / 2 });
    } else {
      setScreenDimension({ width: width - 256, height: height - 250 });
    }
  }, []);
  const setInfo = (action) => {
    switch (action.type) {
      case "item_id":
        setSelectedInfo({
          item_id: action.value,
          month: selectedInfo.month,
          year: selectedInfo.year,
        });

        break;
      case "month":
        setSelectedInfo({
          item_id: selectedInfo.item_id,
          month: action.value,
          year: selectedInfo.year,
        });

        break;
      case "year":
        setSelectedInfo({
          item_id: selectedInfo.item_id,
          month: selectedInfo.month,
          year: action.value,
        });

        break;
      default:
        console.log("not a accepted type of action");
    }
  };
  return (
    <>
      <ReportPage />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {
          <select
            name="item"
            key="item"
            onChange={(e) => {
              setInfo({ type: "item_id", value: e.target.value });
            }}
            className="bg-secondary text-white rounded"
          >
            <option value="" key="" hidden>
              select item
            </option>

            {items.map((E) => {
              return (
                <option value={E._id} key={E._id}>
                  {E.item_name}
                </option>
              );
            })}
          </select>
        }
        <select
          key={"month"}
          name="month"
          onChange={(e) => {
            setInfo({ type: "month", value: e.target.value });
          }}
          className="bg-secondary text-white rounded"
        >
          <option value="none" key="none" hidden>
            select month
          </option>
          {month.map((e) => {
            return (
              <React.Fragment key={e.month}>
                <option value={e.monthNum} key={e.monthNum}>
                  {e.month}
                </option>
              </React.Fragment>
            );
          })}
        </select>
        <select
          name="year"
          key={"year"}
          onChange={(e) => {
            setInfo({ type: "year", value: e.target.value });
          }}
          className="bg-secondary text-white rounded"
        >
          <option value="select-year" key="select-year" hidden>
            select year
          </option>

          <option value={2023} key="2023">
            2023
          </option>
          <option value={2024} key="2024">
            2024
          </option>
          <option value={2025} key={"2025"}>
            2025
          </option>
          <option value={2026} key="2026">
            2026
          </option>
          <option value={2027} key="2027">
            2027
          </option>
        </select>
      </div>
      {isLoading && <LoadingSpinner />}
      {data.data.length > 0 ? (
        <div>
          <AreaChart
            width={screenDimension.width}
            height={screenDimension.height - 20}
            data={data.data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="_id"
              label={{
                value: `${data.itemName}`,
                offset: 3,
                position: "insideBottom",
              }}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="totalAmount"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </div>
      ) : (
        <h1>{data.message}</h1>
      )}
    </>
  );
};

export default ItemsMonthlyReport;
